import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import ImageIcon from "../../../assets/images/img-icon.png";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { createQuestion } from "../../../redux/action/questionAction";
import { categoryDropdownList } from "../../../redux/action/categoryAction";

const initialState = {
  questionImage: "",
  questionImageReview: "",
  questionCode: "",
  questionText: "",
  answer: "",
  imagePlacement: "",
  category: "",
  level: "",
  battalion: "",
  optionList: [{ optionText: "", isCorrect: false }],
  optionPlacement: "",
  imageReference: "",
  moderatorComment: "",
  errors: "",
};
const Rightsidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const listData = useSelector((state) => state.category);
  const { categoryDropdownListData } = listData;

  const {
    optionList,
    questionImage,
    questionImageReview,
    questionCode,
    questionText,
    answer,
    imagePlacement,
    category,
    level,
    battalion,
    optionPlacement,
    imageReference,
    moderatorComment,
    errors,
  } = iState;

  useEffect(() => {
    dispatch(categoryDropdownList());
  }, [categoryDropdownList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const fileHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        previewsFile(file);
      } else {
        toast.error("Only formats are allowed: jpeg, jpg, png files", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const previewsFile = (file) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        updateState({
          ...iState,
          questionImageReview: reader.result,
          questionImage: file,
        });
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  let handleValidation = () => {
    let questionCodeEmpty = "";
    let categoryEmpty = "";
    let answerEmpty = "";
    let levelEmpty = "";
    let formIsValid = true;

    if (!questionCode.trim()) {
      questionCodeEmpty = "Please select question code.";
      formIsValid = false;
    }
    if (!category.trim()) {
      categoryEmpty = "Please select category";
      formIsValid = false;
    }
    if (!answer.trim()) {
      answerEmpty = "Please insert answer";
      formIsValid = false;
    }
    if (!level.trim()) {
      levelEmpty = "Please insert level";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: {
        questionCodeEmpty,
        categoryEmpty,
        answerEmpty,
        levelEmpty,
      },
    });
    return formIsValid;
  };

  const handleInputChange = (e, index) => {
    const { value, name } = e.target;
    const list = [...optionList];
    list[index][name] = value;
    updateState({
      ...iState,
      optionList: list,
    });
  };

  const handleAdd = (index) => {
    if (optionList.length < 4) {
      const updatedOptions = [...optionList];
      updateState({
        ...iState,
        optionList: [
          ...iState.optionList,
          { optionText: "", isCorrect: false },
        ],
      });
    }
  };

  const handleRemove = (e, index) => {
    e.preventDefault();
    const list = [...optionList];
    list.splice(index, 1);
    updateState({
      ...iState,
      optionList: list,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      const data = {
        questionImage,
        questionCode,
        questionText,
        answer,
        imagePlacement,
        category,
        level,
        battalion,
        options: JSON.stringify(optionList),
        optionPlacement,
        imageReference,
        moderatorComment,
      };
      dispatch(createQuestion(data))
        .then((res) => {
          if (res.code == 200) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              navigate("/question");
            }, 2000);
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              errorMsg: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(`error>>>> ${err}`);
        });
    }
  };

  return (
    <>
      <div className="dashRightside">
        <div className="nftsOuterBx">
          <ToastContainer />
          <div className="nftDetHd">
            <Link to="/question">
              <BiArrowBack />
            </Link>
            Create Question
          </div>
          <div className="createfrmOuter">
            <div className="createFrmBx">
              <Label>Question Code*</Label>
              <Input
                type="select"
                name="questionCode"
                value={questionCode}
                onChange={handleChange}
                className="selInp"
              >
                <option>Select Question Code </option>
                <option value="Bool">Bool</option>
                <option value="Multiple">Multiple</option>
                <option value="Radio">Radio</option>
                <option value="Image">Image</option>
                <option value="Desc">Desc</option>
              </Input>

              <span style={{ color: "red" }}>{errors.questionCodeEmpty}</span>
            </div>
            <div className="createFrmBx">
              <Label>Question</Label>
              <Input
                type="textarea"
                name="questionText"
                value={questionText}
                onChange={handleChange}
                className="createInp2"
              />
            </div>
            <div className="uploadNftImage">
              <Label>Image</Label>

              <i>
                {questionImageReview ? (
                  <img src={questionImageReview} alt="" />
                ) : (
                  <img src={ImageIcon} alt="" />
                )}

                <Input
                  type="file"
                  className="createnftFile"
                  onChange={fileHandler}
                  onClick={(e) => e.target.value == null}
                  accept=".png, .jpg, .jpeg"
                />
              </i>
            </div>

            <div className="propertyList optionList">
              <div className="propertyLeft">
                <Label>Options</Label>
              </div>

              <div className="propertyAdd">
                {optionList.map((x, index) => {
                  return (
                    <div className="inner mb-3">
                      <strong>
                        {index === 0
                          ? "A"
                          : index === 1
                          ? "B"
                          : index === 2
                          ? "C"
                          : index === 3
                          ? "D"
                          : ""}
                      </strong>

                      <input
                        type="text"
                        className="formInput"
                        name="optionText"
                        value={x.optionText}
                        onChange={(e) => handleInputChange(e, index)}
                      />

                      {optionList.length !== 1 && (
                        <span className="remove">
                          <button onClick={(e) => handleRemove(e, index)}>
                            <span class="material-icons">remove</span>
                          </button>
                        </span>
                      )}

                      {optionList.length - 1 === index && (
                        <span className="add">
                          <button onClick={() => handleAdd(index)}>
                            <span class="material-icons">add</span>
                          </button>
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="createFrmBx">
              <Label>Correct Answer*</Label>
              <Input
                type="text"
                name="answer"
                value={answer}
                onChange={handleChange}
                className="createInp"
              />
              <span style={{ color: "red" }}>{errors.answerEmpty}</span>
            </div>

            <div className="createFrmBx">
              <Label>Image Placement</Label>

              <Input
                type="select"
                name="imagePlacement"
                value={imagePlacement}
                onChange={handleChange}
                className="selInp"
              >
                <option>Select Image Placement </option>
                <option value="Left">Left</option>
                <option value="Right">Right</option>
                <option value="Center">Center</option>
                <option value="Up">Up</option>
              </Input>
            </div>
            <div className="createFrmBx">
              <Label>Option Placement</Label>

              <Input
                type="select"
                name="optionPlacement"
                value={optionPlacement}
                onChange={handleChange}
                className="selInp"
              >
                <option>Select Option Placement </option>
                <option value="Vertical">Vertical</option>
                <option value="Horizontal">Horizontal</option>
              </Input>
            </div>

            <div className="createFrmBx">
              <Label>Category*</Label>

              <Input
                type="select"
                name="category"
                value={category}
                onChange={handleChange}
                className="selInp"
              >
                <option>Select Category</option>
                {categoryDropdownListData &&
                categoryDropdownListData.data &&
                categoryDropdownListData.data.length > 0
                  ? categoryDropdownListData.data.map((data, i) => (
                      <option value={data.category}>{data.category}</option>
                    ))
                  : ""}
              </Input>
              <span style={{ color: "red" }}>{errors.categoryEmpty}</span>
            </div>

            <div className="createFrmBx">
              <Label>Image Reference</Label>
              <Input
                type="textarea"
                name="imageReference"
                value={imageReference}
                onChange={handleChange}
                className="createInp"
                placeholder=""
              />
            </div>

            <div className="createFrmBx">
              <Label>moderator Comment</Label>
              <Input
                type="textarea"
                name="moderatorComment"
                value={moderatorComment}
                onChange={handleChange}
                className="createInp"
                placeholder=""
              />
            </div>

            <div className="createFrmBx">
              <Label>Level*</Label>
              <Input
                type="text"
                name="level"
                value={level}
                onChange={handleChange}
                className="createInp"
                placeholder=""
              />
              <span style={{ color: "red" }}>{errors.levelEmpty}</span>
            </div>
            <div className="createFrmBx">
              <Label>Battalion Name</Label>

              <Input
                type="text"
                name="battalion"
                value={battalion}
                onChange={handleChange}
                className="createInp"
                placeholder=""
              />
              <span style={{ color: "red" }}>{errors.supplyEmpty}</span>
            </div>
            <div className="createBtn">
              <Button onClick={handleSubmit}>Save</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rightsidebar;
