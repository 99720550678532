import React, { useState } from "react";
import { Button, Input, FormGroup } from "reactstrap";
import Header from '../../common-components/header/header'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import "./otp.scss";
import { verifyOtp, resendOtp } from "../../../redux/action/loginAction";
const initialState = {
    otp: "",
    otpIdError: "",
    errorMsg: "",
};

const Otp = () => {
    const [iState, updateState] = useState(initialState);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { otp, otpIdError, errorMsg } = iState;
    let email = localStorage.getItem('email');
    const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value,
        });
    };

    let handleValidation = () => {
        let otpIdError = '';
        let formIsValid = true;

        if (!otp) {
            otpIdError = 'Please enter otp.';
            formIsValid = false
        }
        updateState({
            ...iState, otpIdError, errorMsg
        });
        return formIsValid

    }

    const resendOtps = (e) => { 
        e.preventDefault()
        let data = { email }
        dispatch(resendOtp(data))
            .then((res) => {
                if (res.code == 200) {
                    toast.success(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                }
            })
            .catch((err) => {
                console.log(`error>>>> ${err}`)
            })
    }


    const handleSubmit = async (e) => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            let data = { otp }
            dispatch(verifyOtp(data))
                .then((res) => {
                    if (res.code == 200) {
                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        setTimeout(()=>{
                            navigate('/rset-password')
                        },2000)
                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message,
                            otpIdError: '',
                            passwordError: '',
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
        }

    }
    return (
        <>
            <div className="loginOuterBg">
                <div className="container">
                    <Header />
                    <ToastContainer />
                    <div className="LoginOuter">
                        <div className="loginLeft">
                        </div>
                        <div className="LoginBg">
                            <div className="loginBgInn">
                                <div className="loginHd">Verify Otp</div>
                                <form>
                                    <div className="frmbx">
                                        <FormGroup>
                                            <label>Otp</label>
                                            <Input
                                                type="text"
                                                name="otp"
                                                value={otp}
                                                onChange={handleChange}
                                                id="otp"
                                                placeholder="otp"
                                            />
                                        </FormGroup>
                                    </div>
                                    <span style={{ color: 'red' }}>{otpIdError}</span>

                                    <div className="loginbtn">
                                        <Button onClick={handleSubmit}>Verify Otp</Button>
                                        <Button onClick={resendOtps}>Resend Otp</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Otp;
