import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { changePassword } from "../../../redux/action/loginAction";

const initialState = {

    password: "",
    newPassword: "",
    cPassword: "",
    errors: "",
};
const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [iState, updateState] = useState(initialState);
    let adminId = localStorage.getItem('adminId');
    const {
        password,
        newPassword,
        cPassword,
        errors } = iState;

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value,
        });
    };

    let handleValidation = () => {
        let passwordEmpty = ''
        let newPasswordEmpty = ''
        let cPasswordEmpty = ''
        let formIsValid = true;

        if (!password) {
            passwordEmpty = "Please enter password.";
        }

        if (!newPassword.trim()) {
            newPasswordEmpty = "Please enter new password.";
            formIsValid = false;
        }

        if (!cPassword) {
            cPasswordEmpty = "Please enter confirm password.";
            formIsValid = false;
        } else if (newPassword != cPassword) {
            cPasswordEmpty = "New password and confirm password does not match";
            formIsValid = false;
        }


        updateState({
            ...iState, errors: {
                passwordEmpty,
                newPasswordEmpty,
                cPasswordEmpty,

            }

        })
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let formIsValid = handleValidation()
        if (formIsValid) {
            const data = {
                password,
                newPassword,
                adminId
            }
            dispatch(changePassword(data))
                .then((res) => {
                    if (res.code == 200) {
                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        setTimeout(() => {
                            navigate('/dashboard')
                        }, 2000)
                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
        }
    }

    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <ToastContainer />
                    <div className="nftDetHd">Change Password</div>
                    <div className="createfrmOuter">
                        <div className="createFrmBx">
                            <Label>Current Password*</Label>
                            <Input type="password"
                                name="password"
                                value={password}
                                onChange={handleChange}
                                className="createInp"
                                placeholder="Enter Current Password"
                            />
                            <span style={{ color: "red" }}>{errors.passwordEmpty}</span>
                        </div>
                        <div className="createFrmBx">
                            <Label>New Password*</Label>
                            <Input type="password"
                                name="newPassword"
                                value={newPassword}
                                onChange={handleChange} className="createInp" placeholder="Enter New Password" />
                            <span style={{ color: "red" }}>{errors.newPasswordEmpty}</span>
                        </div>

                        <div className="createFrmBx">
                            <Label>Confirm Password*</Label>
                            <Input type="password"
                                name="cPassword"
                                value={cPassword}
                                onChange={handleChange} className="createInp" placeholder="Enter Confirm Password" />
                            <span style={{ color: "red" }}>{errors.cPasswordEmpty}</span>
                        </div>

                        <div className="createBtn">
                            <Button onClick={handleSubmit}>Save</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
