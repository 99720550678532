import { dashboardAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'


export const dashboardDetailsInitiate = () => ({
    type: dashboardAction.DASHBOARD_DETAIL_INITIATE,
})

export const dashboardDetailsSuccess = (data) => ({
    type: dashboardAction.DASHBOARD_DETAIL_SUCCESS,
    payload: data,
})

export const dashboardDetailsFailure = (data) => ({
    type: dashboardAction.DASHBOARD_DETAIL_FAILURE,
})

export function dashboardDetails() {
    return (dispatch) => {
        dispatch(dashboardDetailsInitiate())
        return new Promise((resolve, reject) =>
            axios
                .get(`${config.Url}dashboardDetails`, {
                    headers: { token: localStorage.getItem('adminToken') }
                })
                .then((response) => {
                    const data = response.data
                    if (data.code && data.code == 200) {
                        dispatch(dashboardDetailsSuccess(data))
                    } else {
                        dispatch(dashboardDetailsFailure(data))
                    }
                    resolve(data)
                })
                .catch((err) => {
                    dispatch(dashboardDetailsFailure(err))
                    reject(err)
                }),
        )
    }
}

export const levelCountInitiate = () => ({
    type: dashboardAction.LEVEL_COUNT_INITIATE,
})

export const levelCountSuccess = (data) => ({
    type: dashboardAction.LEVEL_COUNT_SUCCESS,
    payload: data,
})

export const levelCountFailure = (data) => ({
    type: dashboardAction.LEVEL_COUNT_FAILURE,
})

export function levelCount() {
    return (dispatch) => {
        dispatch(levelCountInitiate())
        return new Promise((resolve, reject) =>
            axios
                .get(`${config.Url}levelCount`, {
                    headers: { token: localStorage.getItem('adminToken') }
                })
                .then((response) => {
                    const data = response.data
                    if (data.code && data.code == 200) {
                        dispatch(levelCountSuccess(data))
                    } else {
                        dispatch(levelCountFailure(data))
                    }
                    resolve(data)
                })
                .catch((err) => {
                    dispatch(levelCountFailure(err))
                    reject(err)
                }),
        )
    }
}

