import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { TbRefresh } from "react-icons/tb";
import { IoPencil, IoTrash } from "react-icons/io5";
import ReactLoading from "react-loading";
import { categoryDropdownList } from "../../../redux/action/categoryAction";
import {
  questionList,
  uploadQuestion,
  deleteQuestion,
  deleteManyQuestion,
  pageNumbers,
  filterCategory,
  filterQuestionCode,
  exportQuestionList,
} from "../../../redux/action/questionAction";
import * as XLSX from "xlsx";

const Rightsidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [isFlag, setisFlag] = useState(false);
  const [showModel, setshowModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [manyDeleteModel, setManyDeleteModel] = useState(false);
  const [questionIds, setQuestionIds] = useState(false);
  const [questionFiles, setQuestionFiles] = useState("");
  const [imageFiles, setImageFiles] = useState("");
  const [selectedFilesError, setSelectedFilesError] = useState("");
  const [perPage, SetcurrentPage] = useState(20);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [limit, setLimit] = useState(20);
  const [sortOrder, setSortOrder] = useState("▲");
  const listQuestion = useSelector((state) => state.question);
  const {
    questionListData,
    page_number,
    category,
    questionCode,
    exportQuestionListData,
  } = listQuestion;
  const listData = useSelector((state) => state.category);
  const { categoryDropdownListData } = listData;
  let countQuestionData =
    questionListData &&
    questionListData.data &&
    questionListData.data[0].total.length > 0
      ? questionListData.data[0].total[0].count / limit
      : [];
  useEffect(() => {
    dispatch(categoryDropdownList());
  }, [categoryDropdownList]);

  useEffect(() => {
    let obj = {
      searchVal: "",
      pageNumber: page_number,
      category: category,
      questionCode: questionCode,
    };
    SetcurrentPage(limit * page_number);
    dispatch(questionList(obj));
  }, [questionList]);

  useEffect(() => {
    let obj = {
      searchVal: "",
      category: category,
      questionCode: questionCode,
    };
    dispatch(exportQuestionList(obj));
  }, [category]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected + 1;
    SetcurrentPage(limit * selectedPage);
    dispatch(pageNumbers(selectedPage));
    let obj = {
      searchVal: "",
      pageNumber: selectedPage,
      category: category,
      questionCode: questionCode,
    };
    dispatch(questionList(obj));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(filterCategory(value));
    let obj = {
      searchVal: "",
      pageNumber: page_number,
      category: value,
      questionCode: questionCode,
    };
    dispatch(questionList(obj));
  };

  const handleChangeQuestion = (e) => {
    const { name, value } = e.target;
    dispatch(filterQuestionCode(value));
    let obj = {
      searchVal: "",
      pageNumber: page_number,
      category: category,
      questionCode: value,
    };
    dispatch(questionList(obj));
    //setQuestionCode(value)
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearch(value);
    let obj = {
      searchVal: value,
      pageNumber: page_number,
      category: category,
      questionCode: questionCode,
    };
    dispatch(questionList(obj));
  };

  const refreshPage = () => {
    dispatch(pageNumbers(1));
    dispatch(filterCategory(""));
    dispatch(filterQuestionCode(""));
    let obj = {
      searchVal: "",
      pageNumber: page_number,
      category: "",
      questionCode: "",
    };
    dispatch(questionList(obj));
    SetcurrentPage(20);
    setSearch("");
    setLimit(20);
  };

  const onFileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setQuestionFiles(file);
      } else {
        toast.error("Only formats are allowed: xlsx files", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/zip") {
        setImageFiles(file);
      } else {
        toast.error("Only formats are allowed: zip files", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const openStatsModal = () => {
    setshowModel(true);
  };

  const handleStatsClose = () => {
    setshowModel(false);
  };

  const openDeleteModal = (id) => {
    setQuestionIds(id);
    setDeleteModel(true);
  };

  const handleQuestionClose = () => {
    setDeleteModel(false);
  };

  const openManyDeleteModal = () => {
    setManyDeleteModel(true);
  };

  const handleManyQuestionClose = () => {
    setManyDeleteModel(false);
  };

  let handleValidation = () => {
    let questionFilesEmpty = "";
    let formIsValid = true;

    if (questionFiles.length == 0) {
      questionFilesEmpty = "Please select question files";
      formIsValid = false;
    }
    setSelectedFilesError(questionFilesEmpty);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      setisFlag(true);
      const data = { questionFiles, imageFiles };
      dispatch(uploadQuestion(data))
        .then((res) => {
          if (res.code == 200) {
            setisFlag(false);
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setshowModel(false);
            setImageFiles("");
            setQuestionFiles("");
            let obj = {
              searchVal: "",
              pageNumber: page_number,
              category: category,
              questionCode: questionCode,
            };
            dispatch(questionList(obj));
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(`error>>>> ${err}`);
        });
    }
  };

  const handleDelete = async () => {
    dispatch(deleteQuestion(questionIds))
      .then((res) => {
        if (res.code == 200) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDeleteModel(false);
          let obj = {
            searchVal: "",
            pageNumber: page_number,
            category: category,
            questionCode: questionCode,
          };
          dispatch(questionList(obj));
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        console.log(`error>>>> ${err}`);
      });
  };
  const editQuestion = (questionId) => {
    localStorage.setItem("questionId", questionId);
    navigate("/edit-question");
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedQuestion([]);
    } else {
      setSelectedQuestion(
        questionListData.data &&
          questionListData.data[0].data.map((item) => item._id)
      );
    }
    setSelectAll(!selectAll);
  };

  const handleQuestionSelection = (question_Id) => {
    if (selectedQuestion.includes(question_Id)) {
      setSelectedQuestion(selectedQuestion.filter((id) => id !== question_Id));
    } else {
      setSelectedQuestion([...selectedQuestion, question_Id]);
    }
  };

  const handleManyQuestionDelete = async () => {
    let data = { questionId: selectedQuestion };
    dispatch(deleteManyQuestion(data))
      .then((res) => {
        if (res.code == 200) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setManyDeleteModel(false);
          let obj = {
            searchVal: "",
            pageNumber: page_number,
            category: category,
            questionCode: questionCode,
          };
          dispatch(questionList(obj));
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        console.log(`error>>>> ${err}`);
      });
  };

  const handleSorting = (sortValue, sortDirection) => {
    setSortColumn(sortValue);
    setSortOrder(sortOrder === "▲" ? "▼" : "▲");
    let obj = {
      searchVal: "",
      pageNumber: page_number,
      category: category,
      questionCode: questionCode,
      sortValue: sortValue,
      sortDirection,
    };
    dispatch(questionList(obj));
  };

  const handlePageLimit = (e) => {
    const { name, value } = e.target;
    setLimit(value);
    SetcurrentPage(value);
    let obj = {
      searchVal: "",
      pageNumber: page_number,
      category: category,
      questionCode: questionCode,
      limit: value,
    };

    dispatch(questionList(obj));
  };

  const exportToXLSX = () => {
    const recordsToExport = exportQuestionListData?.data[0]?.data;
   
    const wsData = recordsToExport.map((record, index) => {
      const imageName = record?.questionImage.split('/').pop();
      const baseUrl = `${window.location.protocol}//${window.location.host}/image-download?key=${imageName}`; 
  
      return {
        "No.": index + 1,
        "Question Code": record.questionCode,
        Question: record.questionText,
        A: record?.options[0]?.optionText,
        B: record?.options[1]?.optionText,
        C: record?.options[2]?.optionText,
        D: record?.options[3]?.optionText,
        "Correct Answer": record.answer,
        "Image Name": baseUrl,
        "Image Placement": record.imagePlacement,
        Category: record.category,
        Level: record.level,
        "Battalion Name": record.battalion,
        "Option Placement": record.optionPlacement,
        "Image Reference": record.imageReference,
        "Moderator Comment": record.moderatorComment,
      };
    });

    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, `question.xlsx`);
  };

  return (
    <>
      <div className="dashRightside">
        <div className="transBg">
          <ToastContainer />
          <div className="transUserOuter">
            <div className="transHd">
              <div className="refreshBtn">
                {selectAll || selectedQuestion.length >= 1 ? (
                  <Button onClick={openManyDeleteModal}>
                    <IoTrash />
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="transFltr">
              <div className="fltrBx">
                <Input
                  type="select"
                  name="category"
                  value={questionCode}
                  onChange={handleChangeQuestion}
                  className="selInp"
                >
                  <option>Select Question Code</option>
                  <option value="Bool">Bool</option>
                  <option value="Multiple">Multiple</option>
                  <option value="Radio">Radio</option>
                  <option value="Image">Image</option>
                  <option value="Desc">Desc</option>
                </Input>
              </div>

              <div className="fltrBx">
                <Input
                  type="select"
                  name="category"
                  value={category}
                  onChange={handleChange}
                  className="selInp"
                >
                  <option>Select Category</option>

                  {categoryDropdownListData &&
                  categoryDropdownListData.data &&
                  categoryDropdownListData.data.length > 0
                    ? categoryDropdownListData.data.map((data, i) => (
                        <option value={data.category}>{data.category}</option>
                      ))
                    : ""}
                </Input>
              </div>

              <div className="srchBx">
                <FormGroup>
                  <Input
                    type="text"
                    name="search"
                    id="exampleEmail"
                    placeholder="Search..."
                    value={search}
                    onChange={handleSearch}
                  />
                </FormGroup>
              </div>
              <div className="refreshBtn">
                <Button onClick={refreshPage}>
                  <TbRefresh />
                </Button>
              </div>
              {category ? (
                <div className="fltrBx">
                  <Link to="">
                    {" "}
                    <Button className="createBtn" onClick={exportToXLSX}>
                      Export File
                    </Button>
                  </Link>
                </div>
              ) : (
                ""
              )}

              <div className="fltrBx">
                <Link to="">
                  {" "}
                  <Button className="createBtn" onClick={openStatsModal}>
                    Import File
                  </Button>
                </Link>
              </div>
              <div className="fltrBx">
                <Link to="/create-question">
                  {" "}
                  <Button className="createBtn">+</Button>
                </Link>
              </div>
              {/* <div className="fltrBx"><Link to=''> <Button className="createBtn">Upload  File</Button></Link></div> */}
            </div>
          </div>

          <div className="tableBx">
            <table cellPadding="0" cellSpacing="0" border="0">
              <thead>
                <tr>
                  <th>
                    {" "}
                    <input type="checkbox" onClick={toggleSelectAll} />
                  </th>
                  <th>Sr. No.</th>
                  <th>Question Code</th>
                  <th><Link
                      onClick={() =>
                        handleSorting(
                          "createdAt",
                          sortOrder === "▲" ? "dsc" : "asc"
                        )
                      }
                    >
                      Question ID
                      {sortColumn === "createdAt" && sortOrder === "▲"
                        ? "▼"
                        : "▲"}
                    </Link></th>
                  <th>Question</th>
                  <th>
                    <Link
                      onClick={() =>
                        handleSorting(
                          "category",
                          sortOrder === "▲" ? "dsc" : "asc"
                        )
                      }
                    >
                      Category
                      {sortColumn === "category" && sortOrder === "▲"
                        ? "▼"
                        : "▲"}
                    </Link>
                  </th>
                  <th>
                    <Link
                      onClick={() =>
                        handleSorting(
                          "level",
                          sortOrder === "▲" ? "dsc" : "asc"
                        )
                      }
                    >
                      Level
                      {sortColumn === "level" && sortOrder === "▲" ? "▼" : "▲"}
                    </Link>
                  </th>
                  {/* <th>Question ID</th> */}
                  <th>
                    <Link
                      onClick={() =>
                        handleSorting(
                          "createdAt",
                          sortOrder === "▲" ? "dsc" : "asc"
                        )
                      }
                    >
                      Created Date
                      {sortColumn === "createdAt" && sortOrder === "▲"
                        ? "▼"
                        : "▲"}
                    </Link>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {questionListData.data &&
                questionListData.data[0].data.length > 0 ? (
                  questionListData.data[0].data.map((data, i) => (
                    <tr key={i}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedQuestion.includes(data._id)}
                          onChange={() => handleQuestionSelection(data._id)}
                        />
                      </td>
                      <td>{i + 1 + (perPage - limit)}</td>

                      <td>{data.questionCode}</td>
                      <td>{data.questionId}</td>
                      <td>{data.questionText}</td>
                      <td>{data.category}</td>
                      <td>{data.level}</td>
                      {/* <td>{data._id}</td> */}
                      <td>{moment(data.createdAt).format("MMMM-DD-YYYY")}</td>
                      <td className="actionBtns">
                        <button onClick={() => editQuestion(data._id)}>
                          {" "}
                          <IoPencil />
                        </button>{" "}
                        <button onClick={() => openDeleteModal(data._id)}>
                          <IoTrash />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Data not found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="paginationBx">
            <div className="fltrBx tablecount_outer">
              <Input
                type="select"
                name="limit"
                value={limit}
                onChange={handlePageLimit}
                className="selInp"
              >
                <option value={20} selected>
                  20
                </option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </Input>
              <div className="transHd">
                <div className="refreshBtn">
                  Total Question:
                  {questionListData &&
                  questionListData.data &&
                  questionListData.data[0].total.length > 0
                    ? questionListData.data[0].total[0].count
                    : ""}
                </div>
              </div>
            </div>

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(countQuestionData)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
        <Modal show={showModel} onHide={handleStatsClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Upload Question Answer Files
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ color: "black" }}>
            <div className="statsHd"> Files</div>

            <div className="createFrmBx mb-20">
              <Input
                type="file"
                className="file"
                id="imageUpload"
                onChange={onFileHandler}
                onClick={(e) => e.target.value == null}
              />
              {/* <span style={{ color: "red" }}>{errors.questionFilesEmpty}</span> */}
            </div>

            <div className="statsHd"> Upload Image Folder</div>

            <div className="createFrmBx">
              <Input
                type="file"
                className="file"
                id="imageUpload"
                onChange={handleFileChange}
                onClick={(e) => e.target.value == null}
              />
              <span style={{ color: "red" }}>{selectedFilesError}</span>
            </div>
            {isFlag ? (
              <div className="loaderOuter loader">
                <ReactLoading type="bars" className="loaderInn" />
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit}>
              {" "}
              Save{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={deleteModel} onHide={handleQuestionClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              You want To Delete Question?
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="primary" onClick={handleDelete}>
              Yes
            </Button>
            <Button variant="primary" onClick={handleQuestionClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={manyDeleteModel} onHide={handleManyQuestionClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              You want To Delete All Selected Questions?
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="primary" onClick={handleManyQuestionDelete}>
              Yes
            </Button>
            <Button variant="primary" onClick={handleManyQuestionClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Rightsidebar;
