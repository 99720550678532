import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { Button, FormGroup, Input } from 'reactstrap';
import { TbRefresh } from "react-icons/tb"
import { IoPencil, IoTrash } from "react-icons/io5"
import Switch from '@mui/material/Switch';
// import { categoryList, updateCategoryStatus, deleteCategory } from "../../../redux/action/categoryAction";
import { unitList, deleteUnit } from "../../../redux/action/unitAction";
// import { superCategoryDropdownList } from "../../../redux/action/superCatAction";
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState('')
    const [superCatId, setSuperCatId] = useState('')
    const [deleteModel, setDeleteModel] = useState(false)
    const [unitId, setUnitId,] = useState(false)
    const [perPage, SetcurrentPage] = useState(10)
    const [pageNumber, setpageNumber] = useState(1)
    const listData = useSelector((state) => state.unit);
    const { unitListData } = listData;
    let countData =
        unitListData && (unitListData?.data && unitListData?.data[0]?.total.length > 0) ? unitListData?.data[0]?.total[0]?.count / 10 : []

    useEffect(() => {
        let obj = { searchVal: '', pageNumber: 1 }
        dispatch(unitList(obj));
    }, [unitList]);

    const handlePageChange = (e) => {
        const selectedPage = e.selected + 1
        SetcurrentPage(10 * selectedPage)
        let obj = { searchVal: '', pageNumber: selectedPage }
        dispatch(unitList(obj))
        setpageNumber(selectedPage)
    }

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearch(value)
        let obj = { searchVal: value, pageNumber: 1 }
        dispatch(unitList(obj));
    }

    const refreshPage = () => {
        let obj = { searchVal: '', pageNumber: 1, superCategory: '' }
        dispatch(unitList(obj));
        setSearch("")
    }

    const editUnit = (unitId) => {
        localStorage.setItem('unitId', unitId)
        navigate('/edit-units')
    }


    const openDeleteModal = (id) => {
        setUnitId(id)
        setDeleteModel(true)

    }

    const handleQuestionClose = () => {
        setDeleteModel(false)
    }

    const handleDelete = async () => {
        dispatch(deleteUnit(unitId))
            .then((res) => {
                if (res.code == 200) {
                    toast.success(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    setDeleteModel(false)
                    let obj = { searchVal: '', pageNumber: 1 }
                    dispatch(unitList(obj));
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                }
            })
            .catch((err) => {
                console.log(`error>>>> ${err}`)
            })
    }

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setSuperCatId(value)
    //     let obj = { searchVal: search, pageNumber: pageNumber, superCategory: value }
    //     dispatch(categoryList(obj));
    // };


    return (
        <>
            <div className="dashRightside">
                <div className="transBg">
                    <ToastContainer />
                    <div className="transUserOuter">
                        <div className="transHd">Units</div>
                        <div className="transFltr">
                            {/* <div className="fltrBx">
                                <Input type="select" name="superCatId"
                                    value={superCatId}
                                    onChange={handleChange} className="selInp" >
                                    <option>Select Super Category</option>
                                    {superCatDropdownListData && superCatDropdownListData.data && superCatDropdownListData.data.length > 0 ? (
                                        superCatDropdownListData.data.map((data, i) => (
                                            <option value={data.superCategory}>{data.superCategory}</option>
                                        ))
                                    ) : ""
                                    }

                                </Input>
                            </div> */}
                            <div className="srchBx">
                                <FormGroup>
                                    <Input type="text" name="search" id="exampleEmail" placeholder="Search..." value={search} onChange={handleSearch} />
                                </FormGroup>
                            </div>
                            <div className="refreshBtn">
                                <Button onClick={refreshPage}><TbRefresh /></Button>
                            </div>
                            <div className="fltrBx"><Link to='/create-units'> <Button className="createBtn">Create Units</Button></Link></div>


                        </div>
                    </div>

                    <div className="tableBx">
                        <table cellPadding="0" cellSpacing="0" border="0">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th> Unit Name</th>
                                    {/* <th>Super Category Name</th> */}
                                    <th> Created By</th>
                                    <th> Created Date</th>
                                    {/* <th>Status</th> */}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {unitListData?.data && unitListData?.data[0]?.data.length > 0 ? (
                                    unitListData?.data[0].data.map((data, i) => (
                                        <tr key={i}>
                                            <td>{i + 1 + (perPage - 10)}</td>
                                            <td>{data.unit}</td>
                                            {/* <td>{data.superCategory}</td> */}
                                            <td>{data.userName}</td>
                                            <td>{moment(data.createdAt).format('MMMM-DD-YYYY')}</td>
                                            {/* <td> <div className='ms-2 toggleBtn' onClick={() => handleAuthentication(data._id)}> <Switch {...label} defaultChecked={data.status == 'Active' ? true : false} /></div>

                                            </td> */}
                                            <td className="actionBtns">

                                                <button onClick={() => editUnit(data._id)}> <IoPencil /></button><button onClick={() => openDeleteModal(data._id)}><IoTrash /></button></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr><td>Data not found</td></tr>
                                )
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="paginationBx">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(countData)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>

                <Modal show={deleteModel} onHide={handleQuestionClose}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: "black" }}>You want To Delete Unit?</Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button variant="primary" onClick={handleDelete}>Yes</Button>
                        <Button variant="primary" onClick={handleQuestionClose}>No</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    );
};

export default Rightsidebar;






