import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import ImageIcon from "../../../assets/images/img-icon.png";
import time from "../../../assets/images/time.png";
import map from "../../../assets/images/map.png";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import countdown from "../../../assets/images/countdown.png";
import {
  questionDetails,
  updateQuestion,
  categoryQuestion,
  clearQuestionState
} from "../../../redux/action/questionAction";
import { categoryDropdownList } from "../../../redux/action/categoryAction";

const initialState = {
  questionImage: "",
  questionImageReview: "",
  questionCode: "",
  questionText: "",
  answer: "",
  imagePlacement: "",
  category: "",
  level: "",
  battalion: "",
  optionList: [{ optionText: "", isCorrect: false }],
  optionPlacement: "",
  imageReference: "",
  moderatorComment: "",
  errors: "",
};
const Rightsidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModel, setshowModel] = useState(false);
  const [isFlag, setIsFlag] = useState("top");
  const questionData = useSelector((state) => state.question);
  const { questionDetailData, questionCatData, loader } = questionData;
  const listData = useSelector((state) => state.category);
  const { categoryDropdownListData } = listData;
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUpdate, setImageUpdate] = useState(false)
  let questionId = localStorage.getItem("questionId");
  // console.log({
  //   questionData,
  //   listData
  // })
  const {
    optionList,
    questionImage,
    questionImageReview,
    questionCode,
    questionText,
    answer,
    imagePlacement,
    category,
    level,
    battalion,
    optionPlacement,
    imageReference,
    moderatorComment,
    errors,
  } = iState;

  useEffect(() => {
    dispatch(categoryDropdownList());
  }, [categoryDropdownList]);

  useEffect(() => {
    dispatch(questionDetails(questionId));
  }, [questionDetails, questionId]);

  useEffect(() => {
    if (!_.isEmpty(questionDetailData)) {
      const updateData = _.cloneDeep(iState);
      updateData.questionImageReview =
        (questionDetailData.data && questionDetailData.data.questionImage) ||
        "";
      updateData.questionCode =
        (questionDetailData.data && questionDetailData.data.questionCode) || "";
      updateData.questionText =
        (questionDetailData.data && questionDetailData.data.questionText) || "";
      updateData.optionList =
        questionDetailData.data &&
          questionDetailData.data.options[0].optionText == null
          ? optionList
          : questionDetailData.data.options;
      updateData.answer =
        (questionDetailData.data && questionDetailData.data.answer) || "";
      updateData.imagePlacement =
        (questionDetailData.data && questionDetailData.data.imagePlacement) ||
        "";
      updateData.category =
        (questionDetailData.data && questionDetailData.data.category) || "";
      updateData.level =
        (questionDetailData.data && questionDetailData.data.level) || "";
      updateData.battalion =
        (questionDetailData.data && questionDetailData.data.battalion) || "";
      updateData.optionPlacement =
        (questionDetailData.data && questionDetailData.data.optionPlacement) ||
        "";
      updateData.imageReference =
        (questionDetailData.data && questionDetailData.data.imageReference) ||
        "";
      updateData.moderatorComment =
        (questionDetailData.data && questionDetailData.data.moderatorComment) ||
        "";
      updateState(updateData);
    }
  }, [questionDetailData]);

  useEffect(() => {
    dispatch(
      categoryQuestion(
        questionDetailData.data && questionDetailData.data.category
      )
    );
  }, [questionDetailData.data && questionDetailData.data.category]);

  const handleNextClick = () => {
    clearState()
    const nextIndex =
      currentIndex < questionCatData.data.length - 1 ? currentIndex + 1 : 0;
    dispatch(questionDetails(questionCatData.data[nextIndex]._id));
    localStorage.setItem("questionId", questionCatData.data[nextIndex]._id);
    setCurrentIndex(nextIndex);
  };

  const handlePreviousClick = () => {
    clearState()
    const previousIndex =
      currentIndex > 0 ? currentIndex - 1 : questionCatData.data.length - 1;
    dispatch(questionDetails(questionCatData.data[previousIndex]._id));
    localStorage.setItem("questionId", questionCatData.data[previousIndex]._id);
    setCurrentIndex(previousIndex);
  };

  useEffect(() => {
    if (questionImageReview) {
      const img = new Image();
      img.src = questionImageReview;

      img.onload = () => {
        updateState({
          ...iState,
          questionImageReview: questionImageReview,
        });
      };
    }
  }, [questionImageReview]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const fileHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        previewsFile(file);
      } else {
        toast.error("Only formats are allowed: jpeg, jpg, png files", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const previewsFile = (file) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        updateState({
          ...iState,
          questionImageReview: reader.result,
          questionImage: file,
        });
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const removeImageMain = () => {
    updateState({
      ...iState,
      questionImage: "",
      questionImageReview: "",
    });
  };

  let handleValidation = () => {
    let questionCodeEmpty = "";
    let categoryEmpty = "";
    let answerEmpty = "";
    let levelEmpty = "";
    let formIsValid = true;

    if (!questionCode) {
      questionCodeEmpty = "Please select question code.";
      formIsValid = false;
    }
    if (!category) {
      categoryEmpty = "Please select category";
      formIsValid = false;
    }
    if (!answer) {
      answerEmpty = "Please insert answer";
      formIsValid = false;
    }
    if (!level) {
      levelEmpty = "Please insert level";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: {
        questionCodeEmpty,
        categoryEmpty,
        answerEmpty,
        levelEmpty,
      },
    });
    return formIsValid;
  };

  const handleInputChange = (e, index) => {
    const { value, name } = e.target;
    const list = [...optionList];
    list[index][name] = value;
    updateState({
      ...iState,
      optionList: list,
    });
  };

  const handleAdd = (index) => {
    if (optionList.length < 4) {
      const updatedOptions = [...optionList];
      updateState({
        ...iState,
        optionList: [
          ...iState.optionList,
          { optionText: "", isCorrect: false },
        ],
      });
    }
  };

  const handleRemove = (e, index) => {
    e.preventDefault();
    const list = [...optionList];
    list.splice(index, 1);
    updateState({
      ...iState,
      optionList: list,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      const data = {
        questionId,
        questionImage,
        questionCode,
        questionText,
        answer,
        imagePlacement,
        category,
        level,
        battalion,
        options: JSON.stringify(optionList),
        optionPlacement,
        imageReference,
        moderatorComment,
      };
      dispatch(updateQuestion(data))
        .then((res) => {
          if (res.code == 200) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              navigate("/question");
            }, 2000);
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              errorMsg: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(`error>>>> ${err}`);
        });
    }
  };

  const handleSubmitD = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      const data = {
        questionId,
        questionImage,
        questionCode,
        questionText,
        answer,
        imagePlacement,
        category,
        level,
        battalion,
        options: JSON.stringify(optionList),
        optionPlacement,
        imageReference,
        moderatorComment,
      };
      dispatch(updateQuestion(data))
        .then((res) => {
          if (res.code == 200) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            dispatch(questionDetails(questionId));
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              errorMsg: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(`error>>>> ${err}`);
        });
    }
  };

  const openModal = () => {
    setshowModel(true);
  };
  const handleClose = () => {
    setshowModel(false);
  };

  const changeFlagStatus = (flagStatus) => {
    setIsFlag(flagStatus);
  };
  const clearState = () => {
    dispatch(clearQuestionState())
  }
  return (
    <>
      <div className="dashRightside">
        <div className="nftsOuterBx">
          <ToastContainer />
          <div className="nftsaveOuter">
            <div className="nftDetHd">
              <Link to="/question" onClick={clearState}>
                <BiArrowBack />
              </Link>
              Update Question Details
            </div>
            <div className="createBtn">
              <Button onClick={handleSubmit}>Save</Button>
            </div>
            <div className="createBtn">
              <Button onClick={handleSubmitD}>Save & Continue</Button>
            </div>
          </div>

          <div className="createfrmOuter">
            <div className="createFrmBx">
              <Label>Category*</Label>

              <Input
                type="select"
                name="category"
                value={category}
                onChange={handleChange}
                className="selInp"
              >
                <option>Select Category</option>
                {categoryDropdownListData &&
                  categoryDropdownListData.data &&
                  categoryDropdownListData.data.length > 0
                  ? categoryDropdownListData.data.map((data, i) => (
                    <option value={data.category}>{data.category}</option>
                  ))
                  : ""}
              </Input>
              <span style={{ color: "red" }}>{errors.categoryEmpty}</span>
            </div>

            <div className="createFrmBx">
              <Label>Question Code*</Label>
              <Input
                type="select"
                name="questionCode"
                value={questionCode}
                onChange={handleChange}
                className="selInp"
              >
                <option>Select Question Code </option>
                <option value="Bool">Bool</option>
                <option value="Multiple">Multiple</option>
                <option value="Radio">Radio</option>
                <option value="Image">Image</option>
                <option value="Desc">Desc</option>
              </Input>

              <span style={{ color: "red" }}>{errors.questionCodeEmpty}</span>
            </div>
            <div className="createFrmBx">
              <Label>
                Question (Question ID-
                {questionDetailData.data && questionDetailData.data._id})
                {questionCatData.data && questionCatData.data.length > 1 ? (
                  <div className="nxtPrevBtn">
                    Total Question (
                    {questionCatData.data && questionCatData.data.length}){" "}
                    <button
                      onClick={handlePreviousClick}
                      disabled={currentIndex > 0 ? false : true}
                    >
                      Previous
                    </button>
                    <button
                      onClick={handleNextClick}
                      disabled={
                        questionCatData.data &&
                          questionCatData.data.length === currentIndex + 1
                          ? true
                          : false
                      }
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </Label>

              <Input
                type="textarea"
                name="questionText"
                value={questionText}
                onChange={handleChange}
                className="createInp2"
              />
            </div>

            {(questionCode === "Image" || questionImageReview) &&
              questionCode !== "Desc" &&
              questionCode !== "Bool" ? (
              <div className="uploadNftImage">
                <Label>
                  Image <Link onClick={openModal}>Preview</Link>
                </Label>

                <Link
                  className="clsbtn"
                  style={{ color: "red" }}
                  onClick={removeImageMain}
                >
                  <span>X</span>
                </Link>

                <i style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {questionImageReview.length > 0 ? (
                    <img src={questionImageReview} alt="" />
                  ) : (
                    <img src={ImageIcon} alt="" />
                  )}

                  <Input
                    type="file"
                    className="createnftFile"
                    onChange={fileHandler}
                    onClick={(e) => e.target.value == null}
                    accept=".png, .jpg, .jpeg"
                  />
                </i>
              </div>
            ) : (
              ""
            )}

            <div className="propertyList optionList">
              <div className="propertyLeft">
                <Label>Options</Label>
              </div>

              <div className="propertyAdd">
                {optionList.map((x, index) => {
                  return (
                    <div className="inner mb-3">
                      <strong>
                        {index === 0
                          ? "A"
                          : index === 1
                            ? "B"
                            : index === 2
                              ? "C"
                              : index === 3
                                ? "D"
                                : ""}
                      </strong>

                      <input
                        type="text"
                        className="formInput"
                        name="optionText"
                        value={x.optionText}
                        onChange={(e) => handleInputChange(e, index)}
                      />

                      {optionList.length !== 1 && (
                        <span className="remove">
                          <button onClick={(e) => handleRemove(e, index)}>
                            <span class="material-icons">remove</span>
                          </button>
                        </span>
                      )}

                      {optionList.length - 1 === index && (
                        <span className="add">
                          <button onClick={() => handleAdd(index)}>
                            <span class="material-icons">add</span>
                          </button>
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="createFrmBx">
              <Label>Correct Answer*</Label>
              <Input
                type="text"
                name="answer"
                value={answer}
                onChange={handleChange}
                className="createInp"
              />
              <span style={{ color: "red" }}>{errors.answerEmpty}</span>
            </div>

            <div className="createFrmBx">
              <Label>Image Placement</Label>

              <Input
                type="select"
                name="imagePlacement"
                value={imagePlacement}
                onChange={handleChange}
                className="selInp"
              >
                <option>Select Image Placement </option>
                <option value="Left">Left</option>
                <option value="Right">Right</option>
                <option value="Center">Center</option>
                <option value="Up">Up</option>
              </Input>
            </div>

            <div className="createFrmBx">
              <Label>Option Placement</Label>

              <Input
                type="select"
                name="optionPlacement"
                value={optionPlacement}
                onChange={handleChange}
                className="selInp"
              >
                <option>Select Option Placement </option>
                <option value="Vertical">Vertical</option>
                <option value="Horizontal">Horizontal</option>
              </Input>
            </div>
            <div className="createFrmBx">
              <Label>Image Reference</Label>
              <Input
                type="textarea"
                name="imageReference"
                value={imageReference}
                onChange={handleChange}
                className="createInp"
                placeholder=""
              />
            </div>

            <div className="createFrmBx">
              <Label>moderator Comment</Label>
              <Input
                type="textarea"
                name="moderatorComment"
                value={moderatorComment}
                onChange={handleChange}
                className="createInp"
                placeholder=""
              />
            </div>

            <div className="createFrmBx">
              <Label>Level*</Label>
              <Input
                type="text"
                name="level"
                value={level}
                onChange={handleChange}
                className="createInp"
                placeholder=""
              />
              <span style={{ color: "red" }}>{errors.levelEmpty}</span>
            </div>
            <div className="createFrmBx">
              <Label>Battalion Name</Label>

              <Input
                type="text"
                name="battalion"
                value={battalion}
                onChange={handleChange}
                className="createInp"
                placeholder=""
              />
              <span style={{ color: "red" }}>{errors.supplyEmpty}</span>
            </div>
          </div>

          <Modal className="modal-xl" show={showModel} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Preview Files
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ color: "black" }}>
              <div class="mainBox">
                <div>
                  <ul class="topTabs">
                    <li className={isFlag === "top" ? "active" : ""}>
                      <Link onClick={() => changeFlagStatus("top")}>Top</Link>
                    </li>
                    <li className={isFlag === "left" ? "active" : ""}>
                      <Link onClick={() => changeFlagStatus("left")}>Left</Link>
                    </li>
                    <li className={isFlag === "right" ? "active" : ""}>
                      <Link onClick={() => changeFlagStatus("right")}>
                        Right
                      </Link>
                    </li>
                    <li className={isFlag === "bot" ? "active" : ""}>
                      <Link onClick={() => changeFlagStatus("bot")}>
                        Center
                      </Link>
                    </li>
                  </ul>
                </div>
                <div class="tablet">
                  <div class="tabletInner prevFileOuter">
                    <div class="infoBox">
                      <div class="topBox">
                        <ul class="topLeft">
                          <li>
                            Team: <span>Williams</span>
                          </li>
                          <li>
                            Team: <span>Williams</span>
                          </li>
                          <li>
                            Member: <span>CPL Hall, Riley</span>
                          </li>
                        </ul>
                        <ul class="topRight">
                          <li>
                            <img src={countdown} alt="" />
                          </li>
                          <li>
                            <img src={time} alt="" />
                          </li>
                          <li>Adjust Game</li>
                        </ul>
                      </div>
                      <div class="detBox" id={isFlag}>
                        <div class="imgBox">
                          <img src={questionImageReview} alt="" />
                        </div>
                        {questionDetailData.data &&
                          questionDetailData.data.questionText ? (
                          <div class="qBox">
                            {questionDetailData.data &&
                              questionDetailData.data.questionText}
                          </div>
                        ) : (
                          ""
                        )}

                        {optionList && optionList[0]?.optionText ? (
                          <div className="optBox">
                            <ul>
                              <li>
                                <span>A.</span>
                                {optionList.length > 0
                                  ? optionList[0].optionText
                                  : ""}
                              </li>
                              <li>
                                <span>B.</span>
                                {optionList.length > 1
                                  ? optionList[1].optionText
                                  : ""}
                              </li>
                              <li>
                                <span>C.</span>
                                {optionList.length > 2
                                  ? optionList[2].optionText
                                  : ""}
                              </li>
                              <li>
                                <span>D.</span>
                                {optionList.length > 3
                                  ? optionList[3].optionText
                                  : ""}
                              </li>
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div>
                        <a class="seeAnswer">See Answer</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Rightsidebar;
