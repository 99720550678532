import { unitAction, battalionAction } from "../actionType";

const initialState = {
    loader: false,
    battalionListData: [],
    battalionDropdownListData: [],
    battalionData: {}
};
export default function unit(state = initialState, { type, payload }) {
    switch (type) {
        case battalionAction.BATTALION_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                battalionListData: [],
            };
        }
        case battalionAction.BATTALION_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                battalionListData: payload,
            };
        }
        case battalionAction.BATTALION_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                battalionListData: [],
            };
        }

        case battalionAction.BATTALION_DETAILS_INITIATE: {
            return {
                ...state,
                loader: true,
                battalionData: {},
            };
        }
        case battalionAction.BATTALION_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                battalionData: payload,
            };
        }
        case battalionAction.BATTALION_DETAILS_FAILURE: {
            return {
                ...state,
                loader: false,
                battalionData: {},
            };
        }

        case battalionAction.BATTALION_DROPDOWN_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                battalionDropdownListData: [],
            };
        }
        case battalionAction.BATTALION_DROPDOWN_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                battalionDropdownListData: payload,
            };
        }
        case battalionAction.BATTALION_DROPDOWN_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                battalionDropdownListData: [],
            };
        }
        case battalionAction.CLEAR_BATTALION_STATE: {
            return {
                loader: false,
                battalionListData: [],
                battalionDropdownListData: [],
                battalionData: {}
            }
        }

        default:
            return state;
    }
}
