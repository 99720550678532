import { questionAction } from "../actionType";

const initialState = {
  loader: false,
  questionListData: [],
  flagListData: [],
  questionDetailData: {},
  page_number: 1,
  category: "",
  questionCode: "",
  questionCatData: [],
  exportQuestionListData: [],
};
export default function question(state = initialState, { type, payload }) {
  switch (type) {
    case questionAction.QUESTION_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        questionListData: [],
      };
    }
    case questionAction.QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        questionListData: payload,
      };
    }
    case questionAction.QUESTION_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        questionListData: [],
      };
    }

    case questionAction.EXPORTQUESTION_LIST_FAILURE: {
      return {
        ...state,
        loader: true,
        exportQuestionListData: [],
      };
    }
    case questionAction.EXPORTQUESTION_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        exportQuestionListData: payload,
      };
    }
    case questionAction.EXPORTQUESTION_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        exportQuestionListData: [],
      };
    }
    case questionAction.QUESTION_DETAILS_INITIATE: {
      return {
        ...state,
        loader: true,
        questionDetailData: {},
      };
    }
    case questionAction.QUESTION_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        questionDetailData: payload,
      };
    }
    case questionAction.QUESTION_DETAILS_FAILURE: {
      return {
        ...state,
        loader: false,
        questionDetailData: {},
      };
    }

    case questionAction.FLAG_REVIEW_QUESTION_INITIATE: {
      return {
        ...state,
        loader: true,
        flagListData: [],
      };
    }
    case questionAction.FLAG_REVIEW_QUESTION_SUCCESS: {
      return {
        ...state,
        loader: false,
        flagListData: payload,
      };
    }
    case questionAction.FLAG_REVIEW_QUESTION_FAILURE: {
      return {
        ...state,
        loader: false,
        flagListData: [],
      };
    }

    case questionAction.CATEGORY_QUESTION_INITIATE: {
      return {
        ...state,
        loader: true,
        questionCatData: [],
      };
    }
    case questionAction.CATEGORY_QUESTION_SUCCESS: {
      return {
        ...state,
        loader: false,
        questionCatData: payload,
      };
    }
    case questionAction.CATEGORY_QUESTION_FAILURE: {
      return {
        ...state,
        loader: false,
        questionCatData: [],
      };
    }

    case questionAction.QUESTION_PAGE_NUMBER: {
      return {
        ...state,
        page_number: payload,
      };
    }
    case questionAction.QUESTION_CATEGORY: {
      return {
        ...state,
        category: payload,
      };
    }
    case questionAction.QUESTION_CODE: {
      return {
        ...state,
        questionCode: payload,
      };
    }
    case questionAction.CLEAR_QUESTION_STATE: {
      return {
        loader: false,
        questionListData: [],
        flagListData: [],
        questionDetailData: {},
        page_number: 1,
        category: "",
        questionCode: "",
        questionCatData: [],
        exportQuestionListData: [],
      }
    }
    default:
      return state;
  }
}
