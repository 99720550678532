import { unitAction } from "../actionType";

const initialState = {
    loader: false,
    unitListData: [],
    unitDropdownListData: [],
    unitData: {}
};
export default function unit(state = initialState, { type, payload }) {
    switch (type) {
        case unitAction.UNIT_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                unitListData: [],
            };
        }
        case unitAction.UNIT_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                unitListData: payload,
            };
        }
        case unitAction.UNIT_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                unitListData: [],
            };
        }

        case unitAction.UNIT_DETAILS_INITIATE: {
            return {
                ...state,
                loader: true,
                unitData: {},
            };
        }
        case unitAction.UNIT_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                unitData: payload,
            };
        }
        case unitAction.UNIT_DETAILS_FAILURE: {
            return {
                ...state,
                loader: false,
                unitData: {},
            };
        }

        case unitAction.UNIT_DROPDOWN_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                unitDropdownListData: [],
            };
        }
        case unitAction.UNIT_DROPDOWN_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                unitDropdownListData: payload,
            };
        }
        case unitAction.UNIT_DROPDOWN_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                unitDropdownListData: [],
            };
        }
        case unitAction.CLEAR_UNIT_STATE: {
            return {
                loader: false,
                unitListData: [],
                unitDropdownListData: [],
                unitData: {}
            }
        }

        default:
            return state;
    }
}
