import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';



const ImageDownload = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);    
    const [imageUrl, setImageUrl] = useState('');
    const [ key , setKey ] = useState();

    useEffect(() => {
        const imageKey = searchParams.get("key");
      
        if (imageKey) {        
            setKey(imageKey);
            downloadImage(imageKey);
        }
    }, []);   

    const downloadImage = async (imageKey) => {
        const imageBaseUrl = `https://bucketforpgpt.s3.us-east-2.amazonaws.com/${imageKey}`;
        // const targetWidth = 243;
        // const targetHeight = 389;
        const targetWidth = 389;
        const targetHeight = 243;
        try {
            const response = await fetch(imageBaseUrl);
            const blob = await response.blob();
    
            // Resize the image with padding
            const resizedBlob = await resizeImageWithPadding(blob, targetWidth, targetHeight);
    
            const reader = new FileReader();
            reader.readAsDataURL(resizedBlob);
            reader.onloadend = () => {
                const base64data = reader.result;
                // Create a temporary link element to initiate the download
                const downloadLink = document.createElement('a');
                downloadLink.href = base64data;
                downloadLink.download = imageKey;
    
                // Use a timeout to ensure that the download link is appended to the DOM
                setTimeout(() => {
                    // Trigger the click event to initiate the download
                    downloadLink.click();
                }, 0);
            };
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };
    
    
    
    
    const resizeImageWithPadding = async (blob, targetWidth, targetHeight) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(blob);
            img.onload = () => {
                const width = img.width;
                const height = img.height;
    
                let newWidth = targetWidth;
                let newHeight = targetHeight;
    
                // Calculate aspect ratio of original image
                const aspectRatio = width / height;
    
                // Adjust dimensions while maintaining aspect ratio
                if (aspectRatio > targetWidth / targetHeight) {
                    // Landscape image, adjust height
                    newHeight = Math.round(targetWidth / aspectRatio);
                } else {
                    // Portrait image, adjust width
                    newWidth = Math.round(targetHeight * aspectRatio);
                }
    
                // Create canvas with specified dimensions
                const canvas = document.createElement('canvas');
                canvas.width = targetWidth;
                canvas.height = targetHeight;
    
                // Calculate padding
                const xOffset = Math.round((targetWidth - newWidth) / 2);
                const yOffset = Math.round((targetHeight - newHeight) / 2);
    
                // Draw image onto canvas with padding
                const ctx = canvas.getContext('2d');
                ctx.fillStyle = 'white'; // Set padding color to white
                ctx.fillRect(0, 0, targetWidth, targetHeight); // Fill entire canvas with padding color
                ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
    
                // Convert canvas to blob
                canvas.toBlob((resizedBlob) => {
                    resolve(resizedBlob);
                }, 'image/jpeg');
            };
            img.onerror = (error) => {
                reject(error);
            };
        });
    };
        


    // return (
    //     <div>
         
    //     </div>
    // );
};

export default ImageDownload;



