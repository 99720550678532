import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Label, Input } from 'reactstrap';
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import _ from 'lodash'
import { editUnit, unitDetails, clearUnitState } from "../../../redux/action/unitAction";
import ImageIcon from "../../../assets/images/img-icon.png";

const initialState = {
    unit: "",
    errors: "",
    unitImage: "",
    unitImageReview: "",
};
const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [iState, updateState] = useState(initialState);
    const detailData = useSelector((state) => state.unit);
    const { unitData, loader } = detailData;
    let unitId = localStorage.getItem('unitId')
    const location = useLocation()
    const clearState = () => {
        dispatch(clearUnitState())
    }
    // clearState()
    const {
        unit,
        unitImage,
        unitImageReview,
        errors } = iState;
        // console.log(window)
        console.log(location)
        useEffect(() => {
            const handlePopstate = () => {
                console.log("test")
                // Perform actions based on history navigation changes
            };
            window.addEventListener('popstate', handlePopstate);
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [location]);
    
    useEffect(() => {
        dispatch(unitDetails(unitId));
    }, [unitDetails]);
    
    
    
    useEffect(() => {
        if (!_.isEmpty(unitData)) {
            const updateData = _.cloneDeep(iState)
            updateData.unit = unitData.data && unitData.data.unit || ''
            updateData.unitImage = unitData.data && unitData.data.unitImage || ''
            updateState(
                updateData
                )
            }
            
        }, [unitData])
        
        
        const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value,
        });
    };

    const fileHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png"
            ) {
                previewsFile(file);
            } else {
                toast.error("Only formats are allowed: jpeg, jpg, png files", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };



    const previewsFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener(
            "load",
            () => {
                updateState({
                    ...iState,
                    unitImageReview: reader.result,
                    unitImage: file,
                });
            },
            false
        );
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    let handleValidation = () => {

        let unitEmpty = ''
        let formIsValid = true;

        if (!unit) {
            unitEmpty = "Please insert category";
            formIsValid = false;
        }
        updateState({
            ...iState, errors: { unitEmpty }

        })
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let formIsValid = handleValidation()
        if (formIsValid) {
            const data = {
                unitId,
                unit,
                unitImage
            }
            dispatch(editUnit(data))
                .then((res) => {
                    if (res.code == 200) {

                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        setTimeout(() => {
                            navigate('/unit')
                        }, 2000)
                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message,
                            categoryEmpty: '',
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
        }
    }

    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <ToastContainer />
                    <div className="nftDetHd"><Link to="/unit" onClick={clearState}><BiArrowBack /></Link> Edit Unit</div>

                    <div className="createfrmOuter">
                        <div className="uploadNftImage">
                            <Label>Image</Label>

                            <i style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                {loader ? (<h5 style={{ color: "white" }}>Loading</h5>) : unitImageReview || unitImage ?
                                    (
                                        <img src={unitImageReview || unitImage} alt="" />
                                    ) : (
                                        <img src={ImageIcon} alt="" />
                                    )}
                                <Input
                                    type="file"
                                    className="createnftFile"
                                    onChange={fileHandler}
                                    onClick={(e) => e.target.value == null}
                                    accept=".png, .jpg, .jpeg"
                                />
                            </i>
                        </div>
                        <div className="createFrmBx">
                            <Label>Unit Name*</Label>
                            <Input type="text"
                                name="unit"
                                value={unit}
                                onChange={handleChange} className="createInp" />
                            <span style={{ color: "red" }}>{errors.unitEmpty}</span>
                        </div>

                        <div className="createBtn">
                            <Button onClick={handleSubmit}>Save</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
