
import { questionAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'
import { multiPartData } from '../../utils'

//-----------------------------------Upload Question ---------------------------------------//

export const uploadQuestionInitiate = () => ({
  type: questionAction.UPLOAD_QUESTION_INITIATE,
})

export const uploadQuestionSuccess = (data) => ({
  type: questionAction.UPLOAD_QUESTION_SUCCESS,
  payload: data,
})

export const uploadQuestionFailure = (data) => ({
  type: questionAction.UPLOAD_QUESTION_FAILURE,
})

export function uploadQuestion(payload) {
  return (dispatch) => {
    let data = multiPartData(payload)
    dispatch(uploadQuestionInitiate())
    return new Promise((resolve, reject) =>

      axios
        .post(`${config.Url}uploadQuestion`, data, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(uploadQuestionSuccess(data))
          } else {
            dispatch(uploadQuestionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(uploadQuestionFailure(err))
          reject(err)
        }),
    )
  }
}



//-----------------------------------Create Question ---------------------------------------//


export const createQuestionInitiate = () => ({
  type: questionAction.CREATE_QUESTION_INITIATE,
})

export const createQuestionSuccess = (data) => ({
  type: questionAction.CREATE_QUESTION_SUCCESS,
  payload: data,
})

export const createQuestionFailure = (data) => ({
  type: questionAction.CREATE_QUESTION_FAILURE,
})

export function createQuestion(payload) {
  return (dispatch) => {
    let data = multiPartData(payload)
    dispatch(createQuestionInitiate())
    return new Promise((resolve, reject) =>

      axios
        .post(`${config.Url}createQuestion`, data, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(createQuestionSuccess(data))
          } else {
            dispatch(createQuestionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(createQuestionFailure(err))
          reject(err)
        }),
    )
  }
}



//----------------------------------- Question List ---------------------------------------//

export const questionListInitiate = () => ({
  type: questionAction.QUESTION_LIST_INITIATE,
})

export const questionListSuccess = (data) => ({
  type: questionAction.QUESTION_LIST_SUCCESS,
  payload: data,
})

export const questionListFailure = () => ({
  type: questionAction.QUESTION_LIST_FAILURE,
})

export function questionList(payload) {
  return (dispatch) => {
    dispatch(questionListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}questionList?pageNumber=${payload.pageNumber}&search=${payload.searchVal}&category=${payload.category}&questionCode=${payload.questionCode}&sortValue=${payload.sortValue ? payload.sortValue : ''}&limit=${payload.limit ? payload.limit : 20}&sortDirection=${payload.sortDirection ? payload.sortDirection : 'asc'}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(questionListSuccess(data))
          } else {
            dispatch(questionListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(questionListFailure(err))
          reject(err)
        }),
    )
  }
}



//----------------------------------- Question Detail ---------------------------------------//

export const questionDetailsInitiate = () => ({
  type: questionAction.QUESTION_DETAILS_INITIATE,
})

export const questionDetailsSuccess = (data) => ({
  type: questionAction.QUESTION_DETAILS_SUCCESS,
  payload: data,
})

export const questionDetailsFailure = () => ({
  type: questionAction.QUESTION_DETAILS_FAILURE,
})

export function questionDetails(payload) {
  return (dispatch) => {
    dispatch(questionDetailsInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}questionDetails?questionId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(questionDetailsSuccess(data))
          } else {
            dispatch(questionDetailsFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(questionDetailsFailure(err))
          reject(err)
        }),
    )
  }
}

//-----------------------------------Update Question ---------------------------------------//


export const updateQuestionInitiate = () => ({
  type: questionAction.UPDATE_QUESTION_INITIATE,
})

export const updateQuestionSuccess = (data) => ({
  type: questionAction.UPDATE_QUESTION_SUCCESS,
  payload: data,
})

export const updateQuestionFailure = (data) => ({
  type: questionAction.UPDATE_QUESTION_FAILURE,
})

export function updateQuestion(payload) {
  return (dispatch) => {
    let data = multiPartData(payload)
    dispatch(updateQuestionInitiate())
    return new Promise((resolve, reject) =>

      axios
        .put(`${config.Url}updateQuestion`, data, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(updateQuestionSuccess(data))
          } else {
            dispatch(updateQuestionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(updateQuestionFailure(err))
          reject(err)
        }),
    )
  }
}




//-----------------------------------Delete Question ---------------------------------------//

export const deleteQuestionInitiate = () => ({
  type: questionAction.DELETE_QUESTION_INITIATE,
})

export const deleteQuestionSuccess = (data) => ({
  type: questionAction.DELETE_QUESTION_SUCCESS,
  payload: data,
})

export const deleteQuestionFailure = (data) => ({
  type: questionAction.DELETE_QUESTION_FAILURE,
})

export function deleteQuestion(payload) {
  return (dispatch) => {
    dispatch(deleteQuestionInitiate())
    return new Promise((resolve, reject) =>

      axios
        .delete(`${config.Url}deleteQuestion?questionId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(deleteQuestionSuccess(data))
          } else {
            dispatch(deleteQuestionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(deleteQuestionFailure(err))
          reject(err)
        }),
    )
  }
}



//-----------------------------------Delete  Many Question ---------------------------------------//

export const deleteManyQuestionInitiate = () => ({
  type: questionAction.DELETE_MANY_QUESTION_INITIATE,
})

export const deleteManyQuestionSuccess = (data) => ({
  type: questionAction.DELETE_MANY_QUESTION_SUCCESS,
  payload: data,
})

export const deleteManyQuestionFailure = (data) => ({
  type: questionAction.DELETE_MANY_QUESTION_FAILURE,
})

export function deleteManyQuestion(payload) {
  return (dispatch) => {

    dispatch(deleteManyQuestionInitiate())
    return new Promise((resolve, reject) =>
      axios
        .delete(`${config.Url}deleteManyQuestion`, {
          data: payload,
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(deleteManyQuestionSuccess(data))
          } else {
            dispatch(deleteManyQuestionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(deleteManyQuestionFailure(err))
          reject(err)
        }),
    )
  }
}


//----------------------------------- Flagged Review List ---------------------------------------//

export const flagReviewListInitiate = () => ({
  type: questionAction.FLAG_REVIEW_QUESTION_INITIATE,
})

export const flagReviewListSuccess = (data) => ({
  type: questionAction.FLAG_REVIEW_QUESTION_SUCCESS,
  payload: data,
})

export const flagReviewListFailure = () => ({
  type: questionAction.FLAG_REVIEW_QUESTION_FAILURE,
})

export function flagReviewList(payload) {
  return (dispatch) => {
    dispatch(flagReviewListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}flaggedReviewList?pageNumber=${payload.pageNumber}&search=${payload.searchVal}&limit=${payload.limit ? payload.limit : 10}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(flagReviewListSuccess(data))
          } else {
            dispatch(flagReviewListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(flagReviewListFailure(err))
          reject(err)
        }),
    )
  }
}

//----------------------------------- Update Status List ---------------------------------------//

export const updateStatusInitiate = () => ({
  type: questionAction.QUESTION_UPDATE_STATUS_INITIATE,
})

export const updateStatusSuccess = (data) => ({
  type: questionAction.QUESTION_UPDATE_STATUS_SUCCESS,
  payload: data,
})

export const updateStatusFailure = () => ({
  type: questionAction.QUESTION_UPDATE_STATUS_FAILURE,
})

export function updateStatus(payload) {

  return (dispatch) => {
    dispatch(updateStatusInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}updateQuestionStatus?questionId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(updateStatusSuccess(data))
          } else {
            dispatch(updateStatusFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(updateStatusFailure(err))
          reject(err)
        }),
    )
  }
}



//-----------------------------------Category Question  ---------------------------------------//

export const categoryQuestionInitiate = () => ({
  type: questionAction.CATEGORY_QUESTION_INITIATE,
})

export const categoryQuestionSuccess = (data) => ({
  type: questionAction.CATEGORY_QUESTION_SUCCESS,
  payload: data,
})

export const categoryQuestionFailure = () => ({
  type: questionAction.CATEGORY_QUESTION_FAILURE,
})

export function categoryQuestion(payload) {
  return (dispatch) => {
    dispatch(categoryQuestionInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}categoryQuestion?category=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(categoryQuestionSuccess(data))
          } else {
            dispatch(categoryQuestionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(categoryQuestionFailure(err))
          reject(err)
        }),
    )
  }
}

//-----------------------------------Export Question List ---------------------------------------//

export const exportQuestionListInitiate = () => ({
  type: questionAction.EXPORTQUESTION_LIST_INITIATE,
})

export const exportQuestionListSuccess = (data) => ({
  type: questionAction.EXPORTQUESTION_LIST_SUCCESS,
  payload: data,
})

export const exportQuestionListFailure = () => ({
  type: questionAction.EXPORTQUESTION_LIST_FAILURE,
})

export function exportQuestionList(payload) {
  return (dispatch) => {
    dispatch(exportQuestionListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}exportQuestionList?search=${payload.searchVal}&category=${payload.category}&questionCode=${payload.questionCode}&sortValue=${payload.sortValue ? payload.sortValue : ''}&sortDirection=${payload.sortDirection ? payload.sortDirection : 'asc'}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(exportQuestionListSuccess(data))
          } else {
            dispatch(exportQuestionListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(exportQuestionListFailure(err))
          reject(err)
        }),
    )
  }
}





export const pageNumbers = (data) => ({
  type: questionAction.QUESTION_PAGE_NUMBER,
  payload: data
})

export const filterCategory = (data) => ({
  type: questionAction.QUESTION_CATEGORY,
  payload: data

})
export const filterQuestionCode = (data) => ({
  type: questionAction.QUESTION_CODE,
  payload: data

})

// ----------------------------------------clear state-------------------------------------------

export const clearQuestionStateAction = () => ({
  type: questionAction.CLEAR_QUESTION_STATE,
})

export function clearQuestionState() {
  return (dispatch) => {
   dispatch(clearQuestionStateAction())
  }
}


