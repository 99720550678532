import React, { useState, useEffect } from "react";
import HeaderAfterLogin from "../../common-components/header-afterlogin/header-afterlogin";
import SideBar from '../../common-components/sidebar/sidebar';
import Rightsidebar from "./setting-rightsidebar.";
import "./setting.scss";


const Setting = () => {

    return (
        <>
            <div className="mainBg">
                <div className="dash_container">
                    <HeaderAfterLogin />
                    <div className="DashMidBx">
                        <SideBar />
                        <Rightsidebar />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Setting;
