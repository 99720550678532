import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Logo from "../../../assets/images/logo.png";
import axios from 'axios';
import config from '../../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import { Input, Button, Modal, ModalBody } from "reactstrap"



const InfoFormDetailsRightsidebar = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [userName, setUserName] = useState("");
    const [dodId, setDodId] = useState("");
    const [formData, setFormData] = useState([]);
    const [infoForm, setInfoForm] = useState([]);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [dynamicFieldValues, setDynamicFieldValues] = useState({});
    const [modal16, setModal16] = useState(false);
    const [backdrop16, setBackdrop16] = useState(false);
    const [keyboard16, setKeyboard16] = useState(false);

    //getting data from url
    useEffect(() => {
        const name = searchParams.get("name");
        const dodId = searchParams.get("dodId");
        if (name) {
            const [firstName, lastName, rank] = name.split(" ");
            setUserName(name);
            setDodId(dodId);
            setDynamicFieldValues({ firstName, lastName, rank });
        }
    }, []);

    useEffect(() => {
        getEnquiryDetails()
    }, [dodId]);

    const getEnquiryDetails = async () => {
        if (dodId) {
            const response = await axios.get(`${config.Url}/getStatus`, {
                params: {
                    dodId: dodId
                }
            });
            if (response.data.code == 200) {
                if (response.data.data.answerUpdated == true) {
                    setUpdateStatus(true)
                } else {
                    axios.get(`${config.Url}getQuestionList`)
                        .then((response) => {
                            if (response.data.code == 200) {
                                setInfoForm(response.data.data)
                            }
                        })
                        .catch((error) => {

                        })
                }

            }
        }
    }

    const handleChange = (e, questionText, dynamicFields, question) => {
        const { name, value } = e.target;
        let text = questionText
            .replace(/\[insert Rank\]/g, dynamicFieldValues.rank)
            .replace(/\[insert first name\]/g, dynamicFieldValues.firstName)
            .replace(/\[insert last name\]/g, dynamicFieldValues.lastName)

        const questionIndex = formData.findIndex((item) => {
            return item.questionText === text;
        });

        if (questionIndex !== -1) {

            const updatedFormData = [...formData];
            updatedFormData[questionIndex].answer = value;
            updatedFormData[questionIndex].question_id = question._id;
            updatedFormData[questionIndex].dodId = dodId;
            updatedFormData[questionIndex].category = "KYT"
            setFormData(updatedFormData);
        } else {

            setFormData((prevState) => [
                ...prevState,
                {
                    questionText: text,
                    answer: value,
                    question_id: question._id,
                    dodId: dodId,
                    category: "KYT",
                    imagePlacement: "Center",
                    questionCode: "Radio",
                    level: 1,
                    battalion: "",
                    questionImage: ""

                },
            ]);
        }
    };

    const handleSubmit = () => {
        let data = {
            queston: formData,
        }
        if (formData.length > 8) {
            axios
                .post(`${config.Url}saveQuestionList`, data)
                .then((response) => {
                    if (response.data.code == 200) {
                        //toast.success(response.data.message)
                        //toggle16();
                        setUpdateStatus(true)
                        setSubmitStatus(true)
                    }
                })
                .catch((error) => {

                })
        } else {
            toast.error("Please update atleast 6 answer");
        }

    };
    const toggle16 = () => {
        setModal16(!modal16);
        setFormData([]);
    };


    return (
        <>
            <ToastContainer />
            <div className="mainCon">
                <div className="header">
                    <div className="logo">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="heading">
                        Hello {dynamicFieldValues.rank} {dynamicFieldValues.lastName} <br />
                        DodID. {dodId}
                    </div>
                </div>
                <div className="formBox">
                    {!updateStatus ? (
                        <>
                            <div className="tc">*Answer should not be more than 20 characters.</div>
                            <ul>
                                {infoForm && infoForm.length > 0 ? (
                                    infoForm.map((question) => {
                                        let cleanedQuestionText = question.questionText
                                            .replace(/\[insert Rank\]/g, dynamicFieldValues.rank)
                                            .replace(/\[insert first name\]/g, dynamicFieldValues.firstName)
                                            .replace(/\[insert last name\]/g, dynamicFieldValues.lastName);


                                        let cleanedQuestionTexts = question.questionText
                                            .replace(/\\\[insert Rank\\\] \\\[insert last name\\\] also/g, 'Languages')
                                            .replace(/\\\[insert Rank\\\] \\\[insert last name\\\] has /g, 'Time in service')
                                            .replace(/\\\[insert Rank\\\] \\\[insert last name\\\] is a/g, 'Specialized schools')
                                            .replace(/\[insert Rank\] \[insert last name\]('s)? /g, 'your ');

                                        cleanedQuestionTexts = cleanedQuestionTexts.split('\n').map(line => {
                                            if (line === "What is your MOS?") {
                                                return "MOS";
                                            } else if (line === "What is your birthdate?") {
                                                return "Birthday";
                                            } else if (line === "What is your Birth year?") {
                                                return "Birth year";
                                            } else if (line === "your has how much time in service?") {
                                                return "Time in service";
                                            } else if (line === "What are your previous duty stations?") {
                                                return "Previous duty stations";
                                            } else if (line === "your is a graduate of which school?") {
                                                return "Specialized schools";
                                            } else if (line === "What is your favorite hobby?") {
                                                return "Favorite hobby";
                                            } else if (line === "Besides english, your also speaks what?") {
                                                return "Languages";
                                            } else if (line === "What is your blood type") {
                                                return "Blood type";
                                            } else if (line === "What is your favorite movie") {
                                                return "Favorite movie";
                                            } else if (line === "What is your favorite food?") {
                                                return "Favorite food";
                                            } else if (line === "What is your nickname?") {
                                                return "Nickname";
                                            } else if (line === "What are Allergies your having?") {
                                                return "Allergies";
                                            } else if (line === "What is your number one fear?") {
                                                return "Number one fear";
                                            } else if (line === "What is your Favorite NHL Team?") {
                                                return "Favorite NHL team";
                                            } else if (line === "What is your Favorite NBA Team?") {
                                                return "Favorite NBA team";
                                            } else if (line === "What is your Favorite NFL Team?") {
                                                return "Favorite NFL team";
                                            } else if (line === "What is your Favorite MLB Team?") {
                                                return "Favorite MLB team";
                                            } else if (line === "What is your Favorite Animal?") {
                                                return "Favorite animal";
                                            } else if (line === "What is your Additional Skill Identifiers?") {
                                                return "Additional Skill Identifiers";
                                            } else {
                                                return line;
                                            }
                                        }).join('\n');

                                        // Check if the question ends with "first name", "last name", or "rank"
                                        const match = question.questionText.match(/(first name|last name|rank)(\s*\?)?$/);

                                        // Check if there is a match
                                        if (match) {
                                            const lastWord = match[1]; // Extract the last matched word
                                            let value = '';

                                            if (lastWord === "first name") {
                                                value = dynamicFieldValues.firstName;
                                            } else if (lastWord === "last name") {
                                                value = dynamicFieldValues.lastName;
                                            } else if (lastWord === 'rank') {
                                                value = dynamicFieldValues.rank;
                                            }

                                            // Create the event object
                                            const e = {
                                                target: {
                                                    value: value
                                                }
                                            };
                                            const questionIndex = formData.findIndex(
                                                (item) => item.questionText === cleanedQuestionText
                                            );
                                            if (questionIndex == -1) {

                                                setFormData((prevState) => [
                                                    ...prevState,
                                                    {
                                                        questionText: cleanedQuestionText,
                                                        answer: value,
                                                        question_id: question._id,
                                                        dodId: dodId,
                                                        category: "KYT",
                                                        imagePlacement: "Center",
                                                        questionCode: "Radio",
                                                        level: 1,
                                                        battalion: "",
                                                        questionImage: ""

                                                    },
                                                ]);
                                            }

                                            return null; // Skip rendering this question
                                        }

                                        return (
                                            <li key={question?.questionText}>
                                                <span className="field" data-question-text={cleanedQuestionText}>
                                                    {cleanedQuestionTexts}
                                                </span>
                                                <input
                                                    type="text"
                                                    className="formInput"
                                                    maxLength="20"
                                                    placeholder={"e.x " + question.options[0].optionText}
                                                    name={question.questionText}
                                                    onChange={(e) => handleChange(e, question.questionText, question.dynamicFields, question)}
                                                />
                                            </li>
                                        );
                                    })
                                ) : (
                                    <li>No questions found.</li>
                                )}
                                <li className="tac">
                                    <button type="button" className="formBtn" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </li>
                            </ul>
                        </>

                    ) : (
                        <>
                            {submitStatus ? (
                                <div className="centered">
                                    <p className="blue-text">Thank you. <br />Answers have been submitted.</p>
                                </div>
                            ) :
                                (
                                    <>
                                        <div style={{ textAlign: 'center' }}>
                                            <h1 style={{ color: 'blue' }}>Your data already updated</h1>
                                        </div>
                                    </>
                                )}
                        </>
                    )}

                </div>
            </div>

            {/* Manage Subscription*/}
            <Modal
                isOpen={modal16}
                toggle={toggle16}
                backdrop={backdrop16}
                keyboard={keyboard16}
                className="mbtsModCont gdPop"
            >
                <ModalBody>
                    <div className="gdpCont">
                        <h2></h2>
                        <p>
                            Thank you. Answers has been submitted.
                        </p>
                        <div className="btnWrp">
                            <Button
                                className="popGdBtn gray_btn"
                                type="submit"
                                onClick={toggle16}
                            >
                                close
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default InfoFormDetailsRightsidebar;




