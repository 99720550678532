import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { dashboardDetails, levelCount } from "../../../redux/action/dashboardAction";


const Rightsidebar = () => {
    const dispatch = useDispatch();
    const detailData = useSelector((state) => state.dashboard);
    const { dashboardData, levelCountData } = detailData;

    useEffect(() => {
        dispatch(dashboardDetails())
    }, [dashboardDetails])

    useEffect(() => {
        dispatch(levelCount())
    }, [levelCount])

    return (
        <>
            <div className="dashRightside">
                <ToastContainer />
                <div className="dashRightBx">
                    <div className="dashR_L">
                        <div className="graphBx_Outer">
                            <div className="walletbalanceBx">

                                <span>Total Moderators</span>
                                <strong>{dashboardData && dashboardData.data && dashboardData.data.totalModerators}</strong>
                            </div>
                            <div className="walletbalanceBx">

                                <span>Total Members</span>
                                <strong> {dashboardData && dashboardData.data && dashboardData.data.totalMember}</strong>

                            </div>
                            <div className="walletbalanceBx">

                                <span>Total Categories</span>
                                <strong>{dashboardData && dashboardData.data && dashboardData.data.totalCategory}</strong>

                            </div>
                            <div className="walletbalanceBx">

                                <span>Total Questions</span>
                                <strong>{dashboardData && dashboardData.data && dashboardData.data.totalQuestion}</strong>

                            </div>

                        </div>
                        <div className="transBg">
                            <ToastContainer />
                            <div className="transUserOuter">
                                <div className="transHd">Question Level Count</div>
                            </div>

                            <ul className="quesanslist">
                                <li><span>Level 1 -{levelCountData && levelCountData.data && levelCountData.data.totalQuestion1 || 0}  Questions</span></li>
                                <li><span>Level 2 -{levelCountData && levelCountData.data && levelCountData.data.totalQuestion2 || 0}  Questions</span></li>
                                <li><span>Level 3 -{levelCountData && levelCountData.data && levelCountData.data.totalQuestion3 || 0}  Questions</span></li>
                                <li><span>Level 4 -{levelCountData && levelCountData.data && levelCountData.data.totalQuestion4 || 0}  Questions</span></li>
                                <li><span>Level 5 -{levelCountData && levelCountData.data && levelCountData.data.totalQuestion5 || 0}  Questions</span></li>
                                <li><span>Level 6 -{levelCountData && levelCountData.data && levelCountData.data.totalQuestion6 || 0}  Questions</span></li>
                                <li><span>Level 7 -{levelCountData && levelCountData.data && levelCountData.data.totalQuestion7 || 0}  Questions</span></li>
                                <li><span>Level 8 -{levelCountData && levelCountData.data && levelCountData.data.totalQuestion8 || 0}  Questions</span></li>
                                <li><span>Level 9 -{levelCountData && levelCountData.data && levelCountData.data.totalQuestion9 || 0}  Questions</span></li>
                                <li><span>Level 10 -{levelCountData && levelCountData.data && levelCountData.data.totalQuestion10 || 0}  Questions</span></li>
                        

                            </ul>

                        </div>
                    </div>

                </div>





                {/* <div className="tableBx">
                    <table cellPadding="0" cellSpacing="0" border="0">
                        <thead>
                            <tr>
                                <th>Level</th>
                                <th>Count</th>

                            </tr>
                        </thead>
                        <tbody>


                            {
                                levelCountData.data &&
                                    levelCountData.data.length > 0 ? (
                                    levelCountData.data.map((data, i) => (
                                        <tr key={i}>
                                            <td>{data._id}</td>
                                            <td>{data.count}</td>


                                        </tr>
                                    ))
                                ) : (
                                    <tr><td>Data not found</td></tr>
                                )
                            }
                        </tbody>
                    </table>
                </div> */}

            </div>
        </>
    );
};

export default Rightsidebar;
