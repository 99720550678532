import React, { useState } from "react";
import { Button } from "reactstrap";
import Logo from "../../../assets/images/logo.png";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { NavLink, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./header-afterlogin.scss";

const HeaderAfterLogin = () => {
  let adminName = localStorage.getItem("adminName");
  const [showModel, setshowModel] = useState(false);

  const headers = [
    "No.",
    "Question Code",
    "Question",
    "A",
    "B",
    "C",
    "D",
    "Correct Answer",
    "Image Name",
    "Image Placement",
    "Category",
    "Level",
    "Battalion Name",
    "Option Placement",
    "Image Reference",
    "Moderator Comment",
  ];
  const teamHeaders = [
    "No.",
    "First Name",
    "Last Name",
    "Title",
    "DoD ID",
    "Difficulty Level",
  ];
  const data = [];
  const headerStyle = {
    font: { bold: true }, // Make the text bold
  };

  const downloadXLSX = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    ws["!cols"] = []; // Initialize column widths
    headers.forEach((header, index) => {
      ws[`A1:${XLSX.utils.encode_col(index + 1)}1`] = headerStyle;
      ws["!cols"].push({ wch: 15 }); // Set a default column width
    });
    const buf = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "pgptQuestion.xlsx");
  };

  const downloadTeamXLSX = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: teamHeaders });
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    ws["!cols"] = []; // Initialize column widths
    teamHeaders.forEach((header, index) => {
      ws[`A1:${XLSX.utils.encode_col(index + 1)}1`] = headerStyle;
      ws["!cols"].push({ wch: 15 }); // Set a default column width
    });
    const buf = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "pgptTeam.xlsx");
  };

  const openManyDeleteModal = () => {
    setshowModel(true);
  };

  const handleManyQuestionClose = () => {
    setshowModel(false);
  };

  return (
    <>
      <header className="aftloginheader">
        <div className="headLeft">
          <Link to="/dashboard">
            <img src={Logo} alt="" />
          </Link>
        </div>

        <div className="headRight adminname">
          <span>Hello, {adminName}.</span>
          <Button className="createBtn" onClick={openManyDeleteModal}>
            Download Sample Files
          </Button>
        </div>

        <Modal show={showModel} onHide={handleManyQuestionClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Download Sample Files
            </Modal.Title>
          </Modal.Header>
          <div className="samplebtn">
            {" "}
            <Button variant="primary" onClick={downloadXLSX}>
              {" "}
              Download Question Sample File
            </Button>
            <Button variant="primary" onClick={downloadTeamXLSX}>
              {" "}
              Download Member Sample File
            </Button>
          </div>

          <Modal.Footer>
            <Button variant="primary" onClick={handleManyQuestionClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
