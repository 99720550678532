import React from "react";
import Logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import "./header.scss";

const Header = () => {


  return (
    <>
      <header>
        <div className="headLeft">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
      
      </header>
    </>
  );
};

export default Header;
