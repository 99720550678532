import { categoryAction } from "../actionType";

const initialState = {
    loader: false,
    categoryListData: [],
    categoryDropdownListData:[],
    categoryData: {}
};
export default function category(state = initialState, { type, payload }) {
    switch (type) {
        case categoryAction.CATEGORY_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                categoryListData: [],
            };
        }
        case categoryAction.CATEGORY_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                categoryListData: payload,
            };
        }
        case categoryAction.CATEGORY_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                categoryListData: [],
            };
        }

        case categoryAction.CATEGORY_DETAILS_INITIATE: {
            return {
                ...state,
                loader: true,
                categoryData: {},
            };
        }
        case categoryAction.CATEGORY_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                categoryData: payload,
            };
        }
        case categoryAction.CATEGORY_DETAILS_FAILURE: {
            return {
                ...state,
                loader: false,
                categoryData: {},
            };
        }

        case categoryAction.CATEGORY_DROPDOWN_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                categoryDropdownListData: [],
            };
        }
        case categoryAction.CATEGORY_DROPDOWN_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                categoryDropdownListData: payload,
            };
        }
        case categoryAction.CATEGORY_DROPDOWN_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                categoryDropdownListData: [],
            };
        }

        default:
            return state;
    }
}
