import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Label, Input } from 'reactstrap';
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, Link } from 'react-router-dom';
import _ from 'lodash'
import { categoryDetails, editCategory } from "../../../redux/action/categoryAction";
import { superCategoryDropdownList } from "../../../redux/action/superCatAction";

const initialState = {
    category: "",
    superCatId:"",
    minQuestionLevel: "",
    errors: "",
};

const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [iState, updateState] = useState(initialState);
    const detailData = useSelector((state) => state.category);
    const { categoryData } = detailData;
    const listData = useSelector((state) => state.superCategory);
    const { superCatDropdownListData } = listData;
    let categoryId = localStorage.getItem('categoryId')
    const {
        category,
        minQuestionLevel,
        superCatId,
        errors } = iState;

    useEffect(() => {
        dispatch(categoryDetails(categoryId));
    }, [categoryDetails]);

    useEffect(() => {
        if (!_.isEmpty(categoryData)) {
            const updateData = _.cloneDeep(iState)
            updateData.category = categoryData.data && categoryData.data.category || ''
            updateData.minQuestionLevel = categoryData.data && categoryData.data.minQuestionLevel || ''
            updateData.superCatId = categoryData.data && categoryData.data.superCatId || ''
            updateState(
                updateData
            )
        }

    }, [categoryData])

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value,
        });
    };

    useEffect(() => {
        dispatch(superCategoryDropdownList());
    }, [superCategoryDropdownList]);

    let handleValidation = () => {

        let categoryEmpty = ''
        let superCatIdEmpty=''
        let minQuestionLevelEmpty=''
        let formIsValid = true;

        if (!category) {
            categoryEmpty = "Please insert category";
            formIsValid = false;
        }

        if (!superCatId.trim()) {
            superCatIdEmpty = "Please select super category";
            formIsValid = false;
        }
        if (!minQuestionLevel) {
            minQuestionLevelEmpty = "Please insert Minimum number of Questions ";
            formIsValid = false;
        }
        updateState({
            ...iState, errors: { categoryEmpty,minQuestionLevelEmpty }

        })
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let formIsValid = handleValidation()
        if (formIsValid) {
            const data = {
                category,
                minQuestionLevel,
                superCatId,
                categoryId
            }
            dispatch(editCategory(data))
                .then((res) => {
                    if (res.code == 200) {

                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        setTimeout(() => {
                            navigate('/category')
                        }, 2000)
                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message,
                            categoryEmpty: '',
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
        }
    }


    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <ToastContainer />
                    <div className="nftDetHd"><Link to="/category"><BiArrowBack /></Link> Edit Category</div>

                    <div className="createfrmOuter">

                    <div className="createFrmBx">
                            <Label>Super Category*</Label>

                            <Input type="select" name="superCatId"
                                value={superCatId}
                                onChange={handleChange} className="selInp" >
                                <option>Select Super Category</option>
                                {superCatDropdownListData && superCatDropdownListData.data && superCatDropdownListData.data.length > 0 ? (
                                    superCatDropdownListData.data.map((data, i) => (
                                        <option value={data._id}>{data.superCategory}</option>
                                    ))
                                ) : ""
                                }

                            </Input>
                            <span style={{ color: "red" }}>{errors && errors.superCatIdEmpty}</span>
                        </div>



                        <div className="createFrmBx">
                            <Label>Category Name*</Label>
                            <Input type="text"
                                name="category"
                                value={category}
                                onChange={handleChange} className="createInp" />
                            <span style={{ color: "red" }}>{errors.categoryEmpty}</span>
                        </div>

                        <div className="createFrmBx">
                            <Label>Minimum number of Questions to answer for next level*
                            </Label>
                            <Input type="text"
                                name="minQuestionLevel"
                                value={minQuestionLevel}
                                onChange={handleChange} className="createInp" />
                            <span style={{ color: "red" }}>{errors.minQuestionLevelEmpty}</span>
                        </div>


                        <div className="createBtn">
                            <Button onClick={handleSubmit}>Save</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
