import { userAction } from "../actionType";

const initialState = {
  loader: false,
  userListData: [],
  userData: {},
  sessionData:[],
};

export default function user(state = initialState, { type, payload }) {
  switch (type) {
    case userAction.USER_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        userListData: [],
      };
    }
    case userAction.USER_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        userListData: payload,
      };
    }
    case userAction.USER_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        userListData: [],
      };
    }

    case userAction.USER_DETAILS_INITIATE: {
      return {
        ...state,
        loader: true,
        userData: {},
      };
    }
    case userAction.USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        userData: payload,
      };
    }
    case userAction.USER_DETAILS_FAILURE: {
      return {
        ...state,
        loader: false,
        userData: {},
      };
    }

    case userAction.SESSION_DETAILS_INITIATE: {
      return {
        ...state,
        loader: true,
        sessionData: [],
      };
    }
    case userAction.SESSION_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        sessionData: payload,
      };
    }
    case userAction.SESSION_DETAILS_FAILURE: {
      return {
        ...state,
        loader: false,
        sessionData: [],
      };
    }

    default:
      return state;
  }
}
