import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Token from "../../../assets/images/Token.png"
import { AiOutlineHeart } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { Link} from 'react-router-dom'
import { collectionDetail } from "../../../redux/action/collectionAction";
import { collectionNftList } from "../../../redux/action/nftAction";


const Rightsidebar = () => {
    const dispatch = useDispatch();
    const detailData = useSelector((state) => state.collection);
    const { collectionDetailData } = detailData;
    const [pageCount, setPageCount] = useState(5)
    const listData = useSelector((state) => state.NFT);
    const { colNftListData } = listData;

    let collectionId = localStorage.getItem('collectionId')

    useEffect(() => {
        dispatch(collectionDetail(collectionId));
    }, [collectionDetail]);

    useEffect(() => {
        let obj = {
            pageCount,
            collectionId
        }
        dispatch(collectionNftList(obj));
    }, [collectionNftList]);

    const handleLoadMore = () => {
        setPageCount(pageCount + 5)
        let obj = {
            pageCount: pageCount + 5,
            collectionId
        }
        dispatch(collectionNftList(obj));
    }


    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <div className="nftDetHd"><Link to="/collections"><BiArrowBack /></Link>Collections Details</div>
                    <div className="collDetBanner">
                        <img src={collectionDetailData.data && collectionDetailData.data.collectionDetail.bannerImage} alt="" />
                    </div>
                    <div className="collectionDetail">
                        <div className="collBx">
                            <span>Collection Name</span>
                            <strong>{collectionDetailData.data && collectionDetailData.data.collectionDetail.collectionName}</strong>
                        </div>
                        <div className="collBx">
                            <span>Items</span>
                            <strong>{collectionDetailData.data && collectionDetailData.data.totalVolumes[0].totalItem}</strong>
                        </div>
                        <div className="collBx">
                            <span>Owners</span>
                            <strong>142</strong>
                        </div>
                        <div className="collBx">
                            <span>Total Volume</span>
                            <strong>{collectionDetailData.data && collectionDetailData.data.totalVolumes[0].totalVolume} GSP</strong>
                        </div>
                        <div className="collBx">
                            <span>Floor Price</span>
                            <strong>{collectionDetailData.data && collectionDetailData.data.totalVolumes[0].florPrice} GSP</strong>
                        </div>
                    </div>
                    <div className="listOutBx">


                        {colNftListData && colNftListData.data &&
                            colNftListData.data.length > 0 ? (
                            colNftListData.data.map((item, i) => (
                                <div class="slideItemBx">
                                    <div className='slideImg'>
                                        <img src={item.nftImage} alt="" />
                                    </div>
                                    <div className='slideTitle'>
                                        <strong>{item.nftName} #12345</strong>
                                        <span>{item.collectionName}</span>
                                    </div>
                                    <div className='likeOuter'>
                                        <div className='likeLeft'>
                                            <img src={Token} alt="" />
                                            {item.price ? item.price : 0}
                                        </div>
                                        <div className='likeright'>
                                            <AiOutlineHeart />
                                            3.2k
                                        </div>
                                    </div>
                                </div>

                            ))
                        ) : (
                            <p> Data not found</p>

                        )
                        }
                    </div>
                    <div className="moreList">
                        <Button onClick={handleLoadMore}>Load More</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
