import React, { useState, useEffect } from "react";
import { Button, Input, FormGroup, Label } from "reactstrap";
import Header from '../../common-components/header/header'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import "./login.scss";
import { adminLogin } from "../../../redux/action/loginAction";


const initialState = {
  email: "",
  password: "",
  rememberme: false,
  emailIdError: "",
  passwordError: "",
  errorMsg: "",
};

const Login = () => {
  const [iState, updateState] = useState(initialState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const { email, password, emailIdError, passwordError, errorMsg } = iState;

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  let handleValidation = () => {
    let emailIdError = "";
    let passwordError = "";
    let formIsValid = true;

    if (!email) {
      emailIdError = "Please enter email ID.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      emailIdError = "Email format is not valid";
      formIsValid = false;
    }
    if (!password) {
      passwordError = "Please enter password.";
    }
    updateState({
      ...iState,
      emailIdError,
      passwordError,
      errorMsg,
    });
    return formIsValid;
  };

  const handleRememberMeChange = (e) => {
    const { name, checked } = e.target
    setRememberMe(checked)
  };
  useEffect(() => {
    if (Cookies.get("rememberMe") == 'checked') {
      const storedUsername = Cookies.get("email");
      const storedPassword = Cookies.get("password");
      updateState({
        ...iState, email: storedUsername || '', password: storedPassword || ''
      });
      setRememberMe(true)
    }
  }, [Cookies.get("rememberMe") == 'checked']);

  const handleSubmit = async (e) => {
    e.preventDefault()
    let formIsValid = handleValidation()
    if (formIsValid) {
      if (rememberMe) {
        Cookies.set('email', email);
        Cookies.set('password', password);
        Cookies.set('rememberMe', 'checked');
      } else {
        Cookies.remove('email');
        Cookies.remove('password');
        Cookies.remove('rememberMe');
      }
      const data = { email, password }
      dispatch(adminLogin(data))
        .then((res) => {
          if (res.code == 200) {
            localStorage.setItem('adminToken', res.data.jwtToken)
            localStorage.setItem('adminId', res.data._id)
            localStorage.setItem('adminName', res.data.userName)
            navigate('./dashboard')
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
            updateState({
              ...iState,
              errorMsg: res.message,
              emailIdError: '',
              passwordError: '',
            })
          }
        })
        .catch((err) => {
          console.log(`error>>>> ${err}`)
        })
    }
  }
  return (
    <>
      <div className="loginOuterBg">
        <div className="container">
          <Header />
          <ToastContainer />
          <div className="LoginOuter">
            <div className="loginLeft">

            </div>
            <div className="LoginBg">
              <div className="loginHd">PGPT Admin</div>
              <div className="loginBgInn">
                <div className="loginHd">Login</div>
                <form>
                  <div className="frmbx">
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        id="email"
                        placeholder="Email"
                      />
                    </FormGroup>
                  </div>
                  <span style={{ color: 'red' }}>{emailIdError}</span>
                  <div className="frmbx">
                    <FormGroup>
                      <label>Password</label>
                      <Input
                        type="password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                        id="password"
                        placeholder="Password"
                      />
                    </FormGroup>
                  </div>
                  <span style={{ color: 'red' }}>{passwordError}</span>
                  <div className="rembBx">
                    <div className="rembLeft">
                      <Label check>
                        <Input type="checkbox" checked={rememberMe} onChange={handleRememberMeChange} />
                        Save Email and Password
                      </Label>
                    </div>
                    <div className="forgPass">
                      <Link to="/forget-password">Forgot Password?</Link>
                    </div>
                  </div>
                  <div className="loginbtn">
                    <Button onClick={handleSubmit}>Login</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
