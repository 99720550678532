import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { TbRefresh } from "react-icons/tb"
import { IoPencil, IoTrash } from "react-icons/io5"
import { deleteQuestion, deleteManyQuestion, flagReviewList } from "../../../redux/action/questionAction";

const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState('')
    const [category, setCategory] = useState('')
    const [questionCode, setQuestionCode] = useState('')
    const [deleteModel, setDeleteModel] = useState(false)
    const [manyDeleteModel, setManyDeleteModel] = useState(false)
    const [questionIds, setQuestionIds] = useState(false)
    const [perPage, SetcurrentPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const [selectedQuestion, setSelectedQuestion] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [limit, setLimit] = useState(10);

    const listQuestion = useSelector((state) => state.question);
    const { flagListData } = listQuestion;
    let countQuestionData =
        flagListData && (flagListData.data && flagListData.data[0].total.length > 0) ? flagListData.data[0].total[0].count / limit : []





    useEffect(() => {
        let obj = { searchVal: '', pageNumber: pageNumber }
        dispatch(flagReviewList(obj));
    }, [flagReviewList]);


    const handlePageChange = (e) => {
        const selectedPage = e.selected + 1
        SetcurrentPage(limit * selectedPage)
        setPageNumber(selectedPage)
        let obj = { searchVal: '', pageNumber: selectedPage }
        dispatch(flagReviewList(obj))
    }



    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearch(value)
        let obj = { searchVal: value, pageNumber: pageNumber }
        dispatch(flagReviewList(obj));
    }

    const refreshPage = () => {
        let obj = { searchVal: '', pageNumber: pageNumber }
        dispatch(flagReviewList(obj));
        setSearch("")
        setLimit(10)
    }


    const openDeleteModal = (id) => {
        setQuestionIds(id)
        setDeleteModel(true)

    }

    const handleQuestionClose = () => {
        setDeleteModel(false)
    }


    const openManyDeleteModal = () => {

        setManyDeleteModel(true)

    }

    const handleManyQuestionClose = () => {
        setManyDeleteModel(false)
    }



    const handleDelete = async () => {
        dispatch(deleteQuestion(questionIds))
            .then((res) => {
                if (res.code == 200) {
                    toast.success(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    setDeleteModel(false)
                    let obj = { searchVal: '', pageNumber: pageNumber}
                    dispatch(flagReviewList(obj));
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                }
            })
            .catch((err) => {
                console.log(`error>>>> ${err}`)
            })
    }
    const editQuestion = (questionId) => {
        localStorage.setItem('questionId', questionId)
        navigate('/edit-flagged-review')

    }

    const toggleSelectAll = () => {
        if (selectAll) {
            setSelectedQuestion([]);
        } else {
            setSelectedQuestion(flagListData.data && flagListData.data[0].data.map((item) => item._id));
        }
        setSelectAll(!selectAll);
    };

    const handleQuestionSelection = (question_Id) => {
        if (selectedQuestion.includes(question_Id)) {
            setSelectedQuestion(selectedQuestion.filter((id) => id !== question_Id));
        } else {
            setSelectedQuestion([...selectedQuestion, question_Id]);
        }
    };

    const handleManyQuestionDelete = async () => {
        let data = { questionId: selectedQuestion }
        dispatch(deleteManyQuestion(data))
            .then((res) => {
                if (res.code == 200) {
                    toast.success(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    setManyDeleteModel(false)
                    let obj = { searchVal: '', pageNumber: 1}
                    dispatch(flagReviewList(obj));
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                }
            })
            .catch((err) => {
                console.log(`error>>>> ${err}`)
            })
    }



    const handlePageLimit = (e) => {
        const { name, value } = e.target;
        setLimit(value)
        SetcurrentPage(value)
        let obj = { searchVal: '', pageNumber: pageNumber, limit: value }

        dispatch(flagReviewList(obj));

    }

    return (
        <>
            <div className="dashRightside">
                <div className="transBg">
                    <ToastContainer />
                    <div className="transUserOuter">
                        <div className="transHd">
                            Flagged For Review

                        </div>
                        <div className="transFltr">

                            <div className="refreshBtn">
                                {(selectAll || selectedQuestion.length >= 1) ? <Button onClick={openManyDeleteModal}><IoTrash /></Button> : ''}

                            </div>

                            <div className="srchBx">
                                <FormGroup>
                                    <Input type="text" name="search" id="exampleEmail" placeholder="Search..." value={search} onChange={handleSearch} />
                                </FormGroup>
                            </div>
                            <div className="refreshBtn">
                                <Button onClick={refreshPage}><TbRefresh /></Button>
                            </div>





                        </div>
                    </div>

                    <div className="tableBx">
                        <table cellPadding="0" cellSpacing="0" border="0">
                            <thead>
                                <tr>
                                    <th> <input type="checkbox" onClick={toggleSelectAll} />
                                    </th>
                                    <th>Sr. No.</th>
                                    <th>Flagged By</th>
                                    <th>Question Code</th>
                                    <th>Question</th>
                                    <th>Category</th>
                                    <th>Level</th>
                                    <th>Flagged Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {flagListData.data && flagListData.data[0].data.length > 0 ? (
                                    flagListData.data[0].data.map((data, i) => (
                                        <tr key={i}>
                                            <td><input
                                                type="checkbox"
                                                checked={selectedQuestion.includes(data._id)}
                                                onChange={() => handleQuestionSelection(data._id)}
                                            /></td>
                                            <td>{i + 1 + (perPage - limit)}</td>
                                            <td>{data.userName}</td>
                                            <td>{data.questionCode}</td>
                                            <td>{data.questionText}</td>
                                            <td>{data.category}</td>
                                            <td>{data.level}</td>
                                
                                            <td>{moment(data.updatedAt).format('MMMM-DD-YYYY')}</td>
                                            <td className="actionBtns">
                                                <button onClick={() => editQuestion(data._id)} > <IoPencil /></button> <button onClick={() => openDeleteModal(data._id)}><IoTrash /></button>
                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr><td>Data not found</td></tr>
                                )
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="paginationBx">
                        <div className="fltrBx">
                            <Input type="select" name="limit" value={limit} onChange={handlePageLimit} className="selInp">
                                <option value={10} selected>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                            </Input>
                        </div>
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(countQuestionData)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>


                </div>


                <Modal show={deleteModel} onHide={handleQuestionClose}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: "black" }}>You want To Delete Question?</Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button variant="primary" onClick={handleDelete}>Yes</Button>
                        <Button variant="primary" onClick={handleQuestionClose}>No</Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={manyDeleteModel} onHide={handleManyQuestionClose}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: "black" }}>You want To Delete All Selected Questions?</Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button variant="primary" onClick={handleManyQuestionDelete}>Yes</Button>
                        <Button variant="primary" onClick={handleManyQuestionClose}>No</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default Rightsidebar;
