import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Label, Input } from 'reactstrap';
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, Link } from 'react-router-dom';
import { createUnit } from "../../../redux/action/unitAction";
import { createBattalion } from "../../../redux/action/battalionAction";
import { unitDropdownList } from "../../../redux/action/unitAction";
import ImageIcon from "../../../assets/images/img-icon.png";
const initialState = {
    battalion: "",
    unit: "",
    battalionImage: "",
    battalionImageReview: "",
    errors: "",
};

const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { unitDropdownListData } = useSelector((state) => state.unit)
    const [iState, updateState] = useState(initialState);
    const {
        battalion,
        unit,
        battalionImage,
        battalionImageReview,
        errors } = iState;

    useEffect(() => {
        dispatch(unitDropdownList())
    }, [unitDropdownList])

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value,
        });
    };

    const fileHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png"
            ) {
                previewsFile(file);
            } else {
                toast.error("Only formats are allowed: jpeg, jpg, png files", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const previewsFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener(
            "load",
            () => {
                updateState({
                    ...iState,
                    battalionImageReview: reader.result,
                    battalionImage: file,
                });
            },
            false
        );
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    let handleValidation = () => {

        let battalionEmpty = ''
        let unitEmpty = ''
        let formIsValid = true;

        if (!unit.trim()) {
            unitEmpty = "Please select unit";
            formIsValid = false;
        }
        if (!battalion.trim()) {
            battalionEmpty = "Please add battalion name";
            formIsValid = false;
        }
        updateState({
            ...iState, errors: { unitEmpty, battalionEmpty }

        })
        return formIsValid;
    }

    const handleSubmit = async (e, isRefresh) => {
        e.preventDefault()
        let formIsValid = handleValidation()
        if (formIsValid) {
            const data = {
                battalion,
                unit,
                battalionImage
            }
            dispatch(createBattalion(data))
                .then((res) => {
                    if (res.code == 200) {

                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        if (!isRefresh) {
                            updateState({
                                battalion: "",
                                unit: "",
                                battalionImage: "",
                                battalionImageReview: "",
                                errors: "",
                            });
                            return
                        }
                        setTimeout(() => {
                            navigate('/battalion')
                        }, 2000)

                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message,
                            unitEmpty: '',
                            battalionEmpty: '',
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
        }
    }


    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <ToastContainer />
                    <div className="nftDetHd"><Link to="/battalion"><BiArrowBack /></Link> Create Battalion</div>

                    <div className="createfrmOuter">
                        <div className="createFrmBx">
                            <Label>Unit*</Label>
                            <select
                                name="unit"
                                value={unit}
                                onChange={handleChange}
                                className="createInp wd-100"
                                placeholder="Select Unit"
                            //  style={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', color: 'white', height:"50px"}}
                            >
                                <option value="">Select Unit</option>
                                {unitDropdownListData && unitDropdownListData.data && unitDropdownListData.data.map((option, index) => {
                                    return (
                                        <option key={index} value={option._id}>{option.unit}</option>
                                    )
                                })}
                            </select>
                            <span style={{ color: "red" }}>{errors.unitEmpty}</span>
                        </div>
                        <div className="uploadNftImage">
                            <Label>Image</Label>

                            <i>
                                {battalionImageReview ? (
                                    <img src={battalionImageReview} alt="" />
                                ) : (
                                    <img src={ImageIcon} alt="" />
                                )}

                                <Input
                                    type="file"
                                    className="createnftFile"
                                    onChange={fileHandler}
                                    onClick={(e) => e.target.value == null}
                                    accept=".png, .jpg, .jpeg"
                                />
                            </i>
                        </div>
                        <div className="createFrmBx">
                            <Label>Battlion Name*</Label>
                            <Input type="text"
                                name="battalion"
                                value={battalion}
                                onChange={handleChange} className="createInp" />
                            <span style={{ color: "red" }}>{errors.battalionEmpty}</span>
                        </div>
                    </div>
                    <div className="createBtnBox">
                        <div className="createBtn">
                            <Button onClick={(e) => handleSubmit(e, true)}>Create</Button>
                        </div>
                        <div className="createBtn">
                            <Button onClick={(e) => handleSubmit(e, false)}>Create Next</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
