import { superCategoryAction } from "../actionType";

const initialState = {
    loader: false,
    superCategoryListData: [],
    superCatDropdownListData:[],
    superCategoryData: {}
};
export default function superCategory(state = initialState, { type, payload }) {
    switch (type) {
        case superCategoryAction.SUPER_CATEGORY_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                superCategoryListData: [],
            };
        }
        case superCategoryAction.SUPER_CATEGORY_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                superCategoryListData: payload,
            };
        }
        case superCategoryAction.SUPER_CATEGORY_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                superCategoryListData: [],
            };
        }

        case superCategoryAction.SUPER_CATEGORY_DETAIL_INITIATE: {
            return {
                ...state,
                loader: true,
                superCategoryData: {},
            };
        }
        case superCategoryAction.SUPER_CATEGORY_DETAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                superCategoryData: payload,
            };
        }
        case superCategoryAction.SUPER_CATEGORY_DETAIL_FAILURE: {
            return {
                ...state,
                loader: false,
                superCategoryData: {},
            };
        }

        case superCategoryAction.SUPER_CATEGORY_DROPDOWN_LIST_INITIATE: {
            return {
                ...state,
                loader: true,
                superCatDropdownListData: [],
            };
        }
        case superCategoryAction.SUPER_CATEGORY_DROPDOWN_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                superCatDropdownListData: payload,
            };
        }
        case superCategoryAction.SUPER_CATEGORY_DROPDOWN_LIST_FAILURE: {
            return {
                ...state,
                loader: false,
                superCatDropdownListData: [],
            };
        }

        default:
            return state;
    }
}
