import { combineReducers } from "redux";
import transaction from "./reducer/transactionReducer";
import user from "./reducer/userReducer";
import category from "./reducer/categoryReducer";
import question from "./reducer/questionReducer"
import superCategory from "./reducer/superCatReducer";
import dashboard from "./reducer/dashboardReducer"
import unit from "./reducer/unitReducer"
import gspUsdPrice from "./reducer/usdPriceReducer";
import tokenTransaction from "./reducer/transactionTokenReducer";
import collection from "./reducer/collectionReducer";
import NFT from "./reducer/nftReducer";
import battalion from "./reducer/battalionReducer";

const rootReducer = combineReducers({
  transaction,
  user,
  category,
  question,
  superCategory,
  dashboard,
  gspUsdPrice,
  tokenTransaction,
  collection,
  NFT,
  unit,
  battalion

});

export default rootReducer;
