import { userAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'

//-----------------------------------Create User ---------------------------------------//

export const createUserInitiate = () => ({
  type: userAction.CREATE_USER_INITIATE,
})

export const createUserSuccess = (data) => ({
  type: userAction.CREATE_USER_SUCCESS,
  payload: data,
})

export const createUserFailure = (data) => ({
  type: userAction.CREATE_USER_FAILURE,
})

export function createUser(payload) {
  return (dispatch) => {
    dispatch(createUserInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}createUser`, payload, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(createUserSuccess(data))
          } else {
            dispatch(createUserFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(createUserFailure(err))
          reject(err)
        }),
    )
  }
}


//----------------------------------- user LIST ---------------------------------------//

export const userListInitiate = () => ({
  type: userAction.USER_LIST_INITIATE,
})

export const userListSuccess = (data) => ({
  type: userAction.USER_LIST_SUCCESS,
  payload: data,
})

export const userListFailure = () => ({
  type: userAction.USER_LIST_FAILURE,
})

export function userList(payload) {
  return (dispatch) => {
    dispatch(userListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}userList?pageNumber=${payload.pageNumber}&search=${payload.searchVal}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(userListSuccess(data))
          } else {
            dispatch(userListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(userListFailure(err))
          reject(err)
        }),
    )
  }
}


export const updateUserStatusInitiate = () => ({
  type: userAction.USER_UPDATE_STATUS_INITIATE,
})

export const updateUserStatusSuccess = (data) => ({
  type: userAction.USER_UPDATE_STATUS_SUCCESS,
  payload: data,
})

export const updateUserStatusFailure = (data) => ({
  type: userAction.USER_UPDATE_STATUS_FAILURE,
})

export function updateUserStatus(payload) {
  return (dispatch) => {
    dispatch(updateUserStatusInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}updateUserStatus?userId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(updateUserStatusSuccess(data))
          } else {
            dispatch(updateUserStatusFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(updateUserStatusFailure(err))
          reject(err)
        }),
    )
  }
}




export const userDetailsInitiate = () => ({
  type: userAction.USER_DETAILS_INITIATE,
})

export const userDetailsSuccess = (data) => ({
  type: userAction.USER_DETAILS_SUCCESS,
  payload: data,
})

export const userDetailsFailure = (data) => ({
  type: userAction.USER_DETAILS_FAILURE,
})

export function userDetails(payload) {
  return (dispatch) => {
    dispatch(userDetailsInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}userDetails?userId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(userDetailsSuccess(data))
          } else {
            dispatch(userDetailsFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(userDetailsFailure(err))
          reject(err)
        }),
    )
  }
}


export const editUserInitiate = () => ({
  type: userAction.EDIT_USER_INITIATE,
})

export const editUserSuccess = (data) => ({
  type: userAction.EDIT_USER_SUCCESS,
  payload: data,
})

export const editUserFailure = (data) => ({
  type: userAction.EDIT_USER_FAILURE,
})

export function editUser(payload) {
  return (dispatch) => {
    dispatch(editUserInitiate())
    return new Promise((resolve, reject) =>
      axios
        .put(`${config.Url}updateUser`, payload, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(editUserSuccess(data))
          } else {
            dispatch(editUserFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(editUserFailure(err))
          reject(err)
        }),
    )
  }
}


export const sessionDetailsInitiate = () => ({
  type: userAction.SESSION_DETAILS_INITIATE,
})

export const sessionDetailsSuccess = (data) => ({
  type: userAction.SESSION_DETAILS_SUCCESS,
  payload: data,
})

export const sessionDetailsFailure = (data) => ({
  type: userAction.SESSION_DETAILS_FAILURE,
})

export function sessionDetails(payload) {
  return (dispatch) => {
    dispatch(sessionDetailsInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}getSessionDetails?userId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(sessionDetailsSuccess(data))
          } else {
            dispatch(sessionDetailsFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(sessionDetailsFailure(err))
          reject(err)
        }),
    )
  }
}