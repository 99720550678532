

import { unitAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'
import { multiPartData } from '../../utils'
// import { dispatch } from 'd3'

//-----------------------------------Create Unit ---------------------------------------//

export const createUnitInitiate = () => ({
  type: unitAction.CREATE_UNIT_INITIATE,
})

export const createUnitSuccess = (data) => ({
  type: unitAction.CREATE_UNIT_SUCCESS,
  payload: data,
})

export const createUnitFailure = (data) => ({
  type: unitAction.CREATE_UNIT_FAILURE,
})

export function createUnit(payload) {
  return (dispatch) => {
    let data = multiPartData(payload)
    dispatch(createUnitInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}createUnit`, data, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(createUnitSuccess(data))
          } else {
            dispatch(createUnitFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(createUnitFailure(err))
          reject(err)
        }),
    )
  }
}


//----------------------------------- UNIT LIST ---------------------------------------//

export const unitListInitiate = () => ({
  type: unitAction.UNIT_LIST_INITIATE,
})

export const unitListSuccess = (data) => ({
  type: unitAction.UNIT_LIST_SUCCESS,
  payload: data,
})

export const unitListFailure = () => ({
  type: unitAction.UNIT_LIST_FAILURE,
})

export function unitList(payload) {
  return (dispatch) => {
    dispatch(unitListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}unitList?pageNumber=${payload.pageNumber}&search=${payload.searchVal}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {

            dispatch(unitListSuccess(data))
          } else {
            dispatch(unitListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(unitListFailure(err))
          reject(err)
        }),
    )
  }
}

// ---------------------------------------------UNIT DETAILS--------------------------------------

export const unitDetailsInitiate = () => ({
  type: unitAction.UNIT_DETAILS_INITIATE,
})

export const unitDetailsSuccess = (data) => ({
  type: unitAction.UNIT_DETAILS_SUCCESS,
  payload: data,
})

export const unitDetailsFailure = (data) => ({
  type: unitAction.UNIT_DETAILS_FAILURE,
})

export function unitDetails(payload) {
  return (dispatch) => {
    dispatch(unitDetailsInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}unitDetails?unitId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(unitDetailsSuccess(data))
          } else {
            dispatch(unitDetailsFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(unitDetailsFailure(err))
          reject(err)
        }),
    )
  }
}



// ------------------------------------------UPDATE UNIT--------------------------------------]

export const updateUnitInitiate = () => ({
  type: unitAction.UNIT_UPDATE_INITIATE,
})

export const updateUnitSuccess = (data) => ({
  type: unitAction.UNIT_UPDATE_SUCCESS,
  payload: data,
})

export const updateUnitFailure = (data) => ({
  type: unitAction.UNIT_UPDATE_FAILURE,
})

export function editUnit(payload) {
  return (dispatch) => {
    let data = multiPartData(payload)
    dispatch(updateUnitInitiate())
    return new Promise((resolve, reject) =>
      axios
        .put(`${config.Url}updateUnit`, data, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(updateUnitSuccess(data))
          } else {
            dispatch(updateUnitFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(updateUnitFailure(err))
          reject(err)
        }),
    )
  }
}

// -------------------------------------DELETE UNIT------------------------------------

export const deleteUnitInitiate = () => ({
  type: unitAction.DELETE_UNIT_INITIATE,
})

export const deleteUnitSuccess = (data) => ({
  type: unitAction.DELETE_UNIT_SUCCESS,
  payload: data,
})

export const deleteUnitFailure = (data) => ({
  type: unitAction.DELETE_UNIT_FAILURE,
})

export function deleteUnit(payload) {
  return (dispatch) => {
    dispatch(deleteUnitInitiate())
    return new Promise((resolve, reject) =>

      axios
        .delete(`${config.Url}deleteUnit?unitId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(deleteUnitSuccess(data))
          } else {
            dispatch(deleteUnitFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(deleteUnitFailure(err))
          reject(err)
        }),
    )
  }
}

//----------------------------------- Unit Dropdown List ---------------------------------------//

export const unitDropdownListInitiate = () => ({
  type: unitAction.UNIT_DROPDOWN_LIST_INITIATE,
})

export const unitDropdownListSuccess = (data) => ({
  type: unitAction.UNIT_DROPDOWN_LIST_SUCCESS,
  payload: data,
})

export const unitDropdownListFailure = () => ({
  type: unitAction.UNIT_DROPDOWN_LIST_FAILURE,
})

export function unitDropdownList() {
  return (dispatch) => {
    dispatch(unitDropdownListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}unitDropDownList`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(unitDropdownListSuccess(data))
          } else {
            dispatch(unitDropdownListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(unitDropdownListFailure(err))
          reject(err)
        }),
    )
  }
}

// ----------------------------------------clear state-------------------------------------------

export const clearUnitStateAction = () => ({
  type: unitAction.CLEAR_UNIT_STATE,
})

export function clearUnitState() {
  return (dispatch) => {
   dispatch(clearUnitStateAction())
  }
}