import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, Link } from 'react-router-dom';
import { createUser } from "../../../redux/action/userAction";
import { unitDropdownList } from "../../../redux/action/unitAction";
import { battalionDropdownList } from "../../../redux/action/battalionAction"

const initialState = {
    userName: "",
    email: "",
    password: "",
    battalion: "",
    selectedImage: "",
    errors: "",
    isImageLoading: false,
    unit: ""
};
const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [unitChange, setUnitChange] = useState(false)
    const [iState, updateState] = useState(initialState);
    const { unitDropdownListData } = useSelector((state) => state.unit)
    const { battalionDropdownListData } = useSelector((state) => state.battalion)
    const {
        userName,
        email,
        password,
        battalion,
        selectedImage,
        errors,
        isImageLoading,
        unit
    } = iState;

    useEffect(() => {
        dispatch(unitDropdownList());
    }, [unitDropdownList]);

    useEffect(() => {
        dispatch(battalionDropdownList(unit));
    }, [battalionDropdownList, unitChange]);

    const battalionOptions = [

        { value: 'HHC', label: 'HHC', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/HHC.png" },
        { value: '1-32 IN REG', label: '1-32 IN REG', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/1-32.png" },
        { value: '2-22 IN REG', label: '2-22 IN REG', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/2-2InREG.png" },
        { value: '3-6 FAR', label: '3-6 FAR', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/3-6.png" },
        { value: '3-71 CAV REG', label: '3-71 CAV REG', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/3-71.png" },
        { value: '7 BEB', label: '7 BEB', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/7BEB.png" },
        { value: '10th BSB', label: '10th BSB', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/10thBSB.png" },
        { value: '2-14 IN REG', label: '2-14 IN REG', images: "https://mbts-assets-0.s3.us-east-2.amazonaws.com/Images/emailImages/img.png" },
        { value: '2-87 IN REG', label: '2-87 IN REG', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/2-87.png" },
        { value: '4-31 IN REG', label: '4-31 IN REG', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/4-31.png" },
        { value: '2-15 FAR', label: '2-15 FAR', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/2-15.png" },
        { value: '1-89 CAV REG', label: '1-89 CAV REG', images: "https://mbts-assets-0.s3.us-east-2.amazonaws.com/Images/emailImages/img.png" },
        { value: '41st  BEB', label: '41st  BEB', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/41stBEB.png" },
        { value: '210th BSB', label: '210th BSB', images: " https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/210BSB.png" },
        { value: '1-10 AV BDE', label: '1-10 AV BDE', images: "https://mbts-assets-0.s3.us-east-2.amazonaws.com/Images/emailImages/img.png" },
        { value: '2-10 AV BDE', label: '2-10 AV BDE', images: "https://mbts-assets-0.s3.us-east-2.amazonaws.com/Images/emailImages/img.png" },
        { value: '3-10 AV BDE', label: '3-10 AV BDE', images: "https://mbts-assets-0.s3.us-east-2.amazonaws.com/Images/emailImages/img.png" },
        { value: '277 SPT BN', label: '277 SPT BN', images: "https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/277SPT-BN.png" },
        { value: '10th STB', label: '10th STB', images: "https://mbts-assets-0.s3.us-east-2.amazonaws.com/Images/emailImages/img.png" },
        { value: '548 CSSB', label: '548 CSSB', images: "https://mbts-assets-0.s3.us-east-2.amazonaws.com/Images/emailImages/img.png" },
        { value: '3-10 AV BDE', label: '3-10 AV BDE', images: "https://mbts-assets-0.s3.us-east-2.amazonaws.com/Images/emailImages/img.png" },
        { value: '710th', label: '710th', images: 'https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/710th.png' },
        { value: '317BEB', label: '317BEB', images: ' https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/317BEB.png' },
        { value: '5-25', label: '5-25', images: '  https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/525.png' },
        { value: '3-89-CAV', label: '3-89-CAV', images: '  https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/389.png' },
        { value: '2-30InREG', label: '2-30InREG', images: 'https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/2-30.png' },
        { value: '2-4InREG', label: '2-4InREG', images: 'https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/2-30.png' },
        { value: '2-2InREG', label: '2-2InREG', images: 'https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/2-2.png' },
        { value: '1-89', label: '1-89', images: ' https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/189.png' },
        { value: '1-87', label: '1-87', images: ' https://bucketforpgpt.s3.us-east-2.amazonaws.com/Images/emailImages/187.png' },
        // Add more options as needed
    ];




    const handleChange = (e) => {
        const { name, value } = e.target;
        const selectedOption = battalionDropdownListData && battalionDropdownListData.data && battalionDropdownListData.data.find(option => option._id === value);
        if (name === "unit") {
            setUnitChange(!unitChange)
        }
        updateState({
            ...iState,
            [name]: value,
            selectedImage: selectedOption ? selectedOption.battalionImage : null,
            isImageLoading: true
        });
    };

    let handleValidation = () => {
        let userNameEmpty = ''
        let emailEmpty = ''
        let passwordEmpty = ''
        let battalionEmpty = ''
        let unitEmpty = ''
        let formIsValid = true;

        if (!userName.trim()) {
            userNameEmpty = "Please enter user name.";
            formIsValid = false;
        }
        if (!email) {
            emailEmpty = "Please enter email ID.";
            formIsValid = false;
        } else if (!/^.+?@.+?\..+$/.test(email)) {
            emailEmpty = "Email format is not valid";
            formIsValid = false;
        }
        if (!password) {
            passwordEmpty = "Please enter password.";
            formIsValid = false;

        }
        if (!battalion) {
            battalionEmpty = "Please select battalion.";
            formIsValid = false;

        } if (!unit) {
            unitEmpty = "Please select Unit.";
            formIsValid = false;

        }


        updateState({
            ...iState, errors: {
                userNameEmpty,
                emailEmpty,
                passwordEmpty,
                battalionEmpty,
                unitEmpty
            }
        })
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let formIsValid = handleValidation()
        if (formIsValid) {
            const data = {
                userName,
                email,
                password,
                battalion,
                unit,
                selectedImage
            }
            dispatch(createUser(data))
                .then((res) => {
                    if (res.code == 200) {
                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        setTimeout(() => {
                            navigate('/moderators')
                        }, 2000)
                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
        }
    }
    const handleImageLoaded = () => {
        updateState({ ...iState, isImageLoading: false })
    }
    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <ToastContainer />
                    <div className="nftDetHd"><Link to="/moderators"><BiArrowBack /></Link> Create Moderator</div>
                    <div className="moderatorFormBox">
                        <div className="createfrmOuter">
                            <div className="createFrmBx">
                                <Label>User Name*</Label>
                                <Input type="text"
                                    name="userName"
                                    value={userName}
                                    onChange={handleChange}
                                    className="createInp"
                                    placeholder="Enter User Name"
                                />
                                <span style={{ color: "red" }}>{errors.userNameEmpty}</span>
                            </div>
                            <div className="createFrmBx">
                                <Label>Email*</Label>
                                <Input type="email"
                                    name="email"
                                    value={email}
                                    onChange={handleChange} className="createInp" placeholder="Enter Email" />
                                <span style={{ color: "red" }}>{errors.emailEmpty}</span>
                            </div>

                            <div className="createFrmBx">
                                <Label>Password*</Label>
                                <Input type="password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange} className="createInp" placeholder="Enter Password" />
                                <span style={{ color: "red" }}>{errors.passwordEmpty}</span>
                            </div>

                            <div className="createFrmBx">
                                <Label>Unit*</Label>
                                <select
                                    name="unit"
                                    value={unit}
                                    onChange={handleChange}
                                    className="createInp wd-100"
                                    placeholder="Select Unit"
                                //  style={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', color: 'white', height:"50px"}}
                                >
                                    <option value="">Select Unit</option>
                                    {unitDropdownListData && unitDropdownListData.data && unitDropdownListData.data.map((option, index) => {
                                        return (
                                            <option key={index} value={option._id}>{option.unit}</option>
                                        )
                                    })}
                                </select>
                                <span style={{ color: "red" }}>{errors.unitEmpty}</span>
                            </div>

                            <div className="createFrmBx">
                                <Label>Battalion*</Label>
                                <select
                                    name="battalion"
                                    value={battalion}
                                    onChange={handleChange}
                                    className="createInp wd-100"
                                    placeholder="Select Battalion"
                                //  style={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', color: 'white', height:"50px"}}
                                >
                                    <option value="">Select Battalion</option>
                                    {battalionDropdownListData && battalionDropdownListData.data && battalionDropdownListData.data.map((option, index) => (
                                        <option key={index} value={option._id}>{option.battalion}</option>
                                    ))}
                                </select>
                                <span style={{ color: "red" }}>{errors.battalionEmpty}</span>
                            </div>


                            <div className="createBtn">
                                <Button onClick={handleSubmit}>Create</Button>
                            </div>
                        </div>

                        <div className="moderatorImg">  {selectedImage && (
                            <div>
                                <img src={selectedImage} alt="Selected Battalion" onLoad={handleImageLoaded}
                                    style={{ display: isImageLoading ? 'none' : 'block' }} />
                                {isImageLoading && <h4 style={{ color: "white" }}>Loading...</h4>}
                            </div>
                        )}</div>

                    </div>
                </div>
            </div>
        </>
    );
};


export default Rightsidebar;
