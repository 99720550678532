import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Input } from 'reactstrap';
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import _ from 'lodash'
import { unitDetails, clearUnitState } from "../../../redux/action/unitAction";
import axios from "axios"
import config from '../../../config/config'
import { useSearchParams } from "react-router-dom";
import { categoryDropdownList } from "../../../redux/action/categoryAction";


const initialState = {
    unit: "",
    errors: "",
    unitImage: "",
    unitImageReview: "",
};
const Rightsidebar = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    // Get a specific query parameter
    const userId = searchParams.get('userId');
    const [iState, updateState] = useState(initialState);
    const [category, setCategory] = useState("")
    const detailData = useSelector((state) => state.unit);
    const { unitData, loader } = detailData;
    let unitId = localStorage.getItem('unitId')
    const listData = useSelector((state) => state.category);
    const { categoryDropdownListData } = listData;

    useEffect(() => {
        dispatch(categoryDropdownList());
      }, []);

    const handleReset = async (e) => {
        e.preventDefault()

        axios
            .get(`${config.Url}reset-question?category=${category}&userId=${userId}`, {
                headers: { token: localStorage.getItem('adminToken') }
            })
            .then((response) => {
                const data = response.data
                if (data.code && data.code == 200) {
                    toast.success(data.data)
                } else {
                    toast.error("some error occured")

                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <ToastContainer />
                    <div className="nftDetHd"><Link to="/moderators"><BiArrowBack /></Link> Reset Submit Answer</div>

                    <div className="createfrmOuter">
                        <div className="fltrBx">
                            <Input
                                type="select"
                                name="category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                className="selInp"
                            >
                                <option value="">Select Category</option>

                                {categoryDropdownListData &&
                                    categoryDropdownListData.data &&
                                    categoryDropdownListData.data.length > 0
                                    ? categoryDropdownListData.data.map((data, i) => (
                                        <option value={data.category}>{data.category}</option>
                                    ))
                                    : ""}
                            </Input>
                        </div>

                        <div className="createBtn mt-5">
                            <Button onClick={handleReset}>Reset</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
