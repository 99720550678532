import { dashboardAction } from "../actionType";

const initialState = {
    loader: false,
    dashboardData: {},
    levelCountData: {}
};
export default function dashboard(state = initialState, { type, payload }) {
    switch (type) {

        case dashboardAction.DASHBOARD_DETAIL_INITIATE: {
            return {
                ...state,
                loader: true,
                dashboardData: {},
            };
        }

        case dashboardAction.DASHBOARD_DETAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                dashboardData: payload,
            };
        }
        case dashboardAction.DASHBOARD_DETAIL_FAILURE: {
            return {
                ...state,
                loader: false,
                dashboardData: {},
            };
        }

        case dashboardAction.LEVEL_COUNT_INITIATE: {
            return {
                ...state,
                loader: true,
                levelCountData: {},
            };
        }

        case dashboardAction.LEVEL_COUNT_SUCCESS: {
            return {
                ...state,
                loader: false,
                levelCountData: payload,
            };
        }
        case dashboardAction.LEVEL_COUNT_FAILURE: {
            return {
                ...state,
                loader: false,
                levelCountData:{},
            };
        }

        default:
            return state;
    }
}
