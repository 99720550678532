
import { categoryAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'

//-----------------------------------Create User ---------------------------------------//

export const createCategoryInitiate = () => ({
  type: categoryAction.CREATE_CATEGORY_INITIATE,
})

export const createCategorySuccess = (data) => ({
  type: categoryAction.CREATE_CATEGORY_SUCCESS,
  payload: data,
})

export const createCategoryFailure = (data) => ({
  type: categoryAction.CREATE_CATEGORY_FAILURE,
})

export function createCategory(payload) {
  return (dispatch) => {
    dispatch(createCategoryInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}createCategory`, payload, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(createCategorySuccess(data))
          } else {
            dispatch(createCategoryFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(createCategoryFailure(err))
          reject(err)
        }),
    )
  }
}


//----------------------------------- user LIST ---------------------------------------//

export const categoryListInitiate = () => ({
  type: categoryAction.CATEGORY_LIST_INITIATE,
})

export const categoryListSuccess = (data) => ({
  type: categoryAction.CATEGORY_LIST_SUCCESS,
  payload: data,
})

export const categoryListFailure = () => ({
  type: categoryAction.CATEGORY_LIST_FAILURE,
})

export function categoryList(payload) {
  return (dispatch) => {
    dispatch(categoryListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}categoryList?pageNumber=${payload.pageNumber}&superCategory=${payload.superCategory}&search=${payload.searchVal}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(categoryListSuccess(data))
          } else {
            dispatch(categoryListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(categoryListFailure(err))
          reject(err)
        }),
    )
  }
}


//----------------------------------- Cetegory Dropdown List ---------------------------------------//

export const categoryDropdownListInitiate = () => ({
  type: categoryAction.CATEGORY_DROPDOWN_LIST_INITIATE,
})

export const categoryDropdownListSuccess = (data) => ({
  type: categoryAction.CATEGORY_DROPDOWN_LIST_SUCCESS,
  payload: data,
})

export const categoryDropdownListFailure = () => ({
  type: categoryAction.CATEGORY_DROPDOWN_LIST_FAILURE,
})

export function categoryDropdownList() {
  return (dispatch) => {
    dispatch(categoryDropdownListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}categoryDropdownList`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(categoryDropdownListSuccess(data))
          } else {
            dispatch(categoryDropdownListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(categoryDropdownListFailure(err))
          reject(err)
        }),
    )
  }
}




export const updateCategoryStatusInitiate = () => ({
  type: categoryAction.CATEGORY_UPDATE_STATUS_INITIATE,
})

export const updateCategoryStatusSuccess = (data) => ({
  type: categoryAction.CATEGORY_UPDATE_STATUS_SUCCESS,
  payload: data,
})

export const updateCategoryStatusFailure = (data) => ({
  type: categoryAction.CATEGORY_UPDATE_STATUS_FAILURE,
})

export function updateCategoryStatus(payload) {
  return (dispatch) => {
    dispatch(updateCategoryStatusInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}updateCategoryStatus?categoryId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(updateCategoryStatusSuccess(data))
          } else {
            dispatch(updateCategoryStatusFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(updateCategoryStatusFailure(err))
          reject(err)
        }),
    )
  }
}



export const categoryDetailsInitiate = () => ({
  type: categoryAction.CATEGORY_DETAILS_INITIATE,
})

export const categoryDetailsSuccess = (data) => ({
  type: categoryAction.CATEGORY_DETAILS_SUCCESS,
  payload: data,
})

export const categoryDetailsFailure = (data) => ({
  type: categoryAction.CATEGORY_DETAILS_FAILURE,
})

export function categoryDetails(payload) {
  return (dispatch) => {
    dispatch(categoryDetailsInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}categoryDatails?categoryId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(categoryDetailsSuccess(data))
          } else {
            dispatch(categoryDetailsFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(categoryDetailsFailure(err))
          reject(err)
        }),
    )
  }
}


export const editCategoryInitiate = () => ({
  type: categoryAction.EDIT_CATEGORY_INITIATE,
})

export const editCategorySuccess = (data) => ({
  type: categoryAction.EDIT_CATEGORY_SUCCESS,
  payload: data,
})

export const editCategoryFailure = (data) => ({
  type: categoryAction.EDIT_CATEGORY_FAILURE,
})

export function editCategory(payload) {
  return (dispatch) => {
    dispatch(editCategoryInitiate())
    return new Promise((resolve, reject) =>
      axios
        .put(`${config.Url}updateCategory`, payload, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(editCategorySuccess(data))
          } else {
            dispatch(editCategoryFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(editCategoryFailure(err))
          reject(err)
        }),
    )
  }
}

export const deleteCategoryInitiate = () => ({
  type: categoryAction.DELETE_CATEGORY_INITIATE,
})

export const deleteCategorySuccess = (data) => ({
  type: categoryAction.DELETE_CATEGORY_SUCCESS,
  payload: data,
})

export const deleteCategoryFailure = (data) => ({
  type: categoryAction.DELETE_CATEGORY_FAILURE,
})

export function deleteCategory(payload) {
  return (dispatch) => {
    dispatch(deleteCategoryInitiate())
    return new Promise((resolve, reject) =>

      axios
        .delete(`${config.Url}deleteCategory?categoryId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(deleteCategorySuccess(data))
          } else {
            dispatch(deleteCategoryFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(deleteCategoryFailure(err))
          reject(err)
        }),
    )
  }
}
