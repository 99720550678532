export const loginAction = {
  ADMIN_LOGIN_INITIATE: "ADMIN_LOGIN_INITIATE",
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  ADMIN_LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",
  ADMIN_FORGET_PASSWORD_INITIATE: "ADMIN_FORGET_PASSWORD_INITIATE",
  ADMIN_FORGET_PASSWORD_SUCCESS: "ADMIN_FORGET_PASSWORD_SUCCESS",
  ADMIN_FORGET_PASSWORD_FAILURE: "ADMIN_FORGET_PASSWORD_FAILURE",
  ADMIN_VERIFYOTP_INITIATE: "ADMIN_VERIFYOTP_INITIATE",
  ADMIN_VERIFYOTP_SUCCESS: "ADMIN_VERIFYOTP_SUCCESS",
  ADMIN_VERIFYOTP_FAILURE: "ADMIN_VERIFYOTP_FAILURE",
  ADMIN_RESENDOTP_INITIATE: "ADMIN_RESENDOTP_INITIATE",
  ADMIN_RESENDOTP_SUCCESS: "ADMIN_RESENDOTP_SUCCESS",
  ADMIN_RESENDOTP_FAILURE: "ADMIN_RESENDOTP_FAILURE",
  ADMIN_RESET_PASSWORD_INITIATE: "ADMIN_RESET_PASSWORD_INITIATE",
  ADMIN_RESET_PASSWORD_SUCCESS: "ADMIN_RESET_PASSWORD_SUCCESS",
  ADMIN_RESET_PASSWORD_FAILURE: "ADMIN_RESET_PASSWORD_FAILURE",
  ADMIN_CHANGE_PASSWORD_INITIATE: "ADMIN_CHANGE_PASSWORD_INITIATE",
  ADMIN_CHANGE_PASSWORD_SUCCESS: "ADMIN_CHANGE_PASSWORD_SUCCESS",
  ADMIN_CHANGE_PASSWORD_FAILURE: "ADMIN_CHANGE_PASSWORD_FAILURE",
};
export const connectWalletAction = {
  ADMIN_CONNECTWALLET_INITIATE: "ADMIN_CONNECTWALLET_INITIATE",
  ADMIN_CONNECTWALLET_SUCCESS: "ADMIN_CONNECTWALLET_SUCCESS",
  ADMIN_CONNECTWALLET_FAILURE: "ADMIN_CONNECTWALLET_FAILURE",
};

export const transactionAction = {
  TRANSACTION_LIST_INITIATE: "TRANSACTION_LIST_INITIATE",
  TRANSACTION_LIST_SUCCESS: "TRANSACTION_LIST_SUCCESS",
  TRANSACTION_LIST_FAILURE: "TRANSACTION_LIST_FAILURE",
};
export const dashboardAction = {
  DASHBOARD_DETAIL_INITIATE: "DASHBOARD_DETAIL_INITIATE",
  DASHBOARD_DETAIL_SUCCESS: "DASHBOARD_DETAIL_SUCCESS",
  DASHBOARD_DETAIL_FAILURE: "DASHBOARD_DETAIL_FAILURE",
  LEVEL_COUNT_INITIATE: "LEVEL_COUNT_INITIATE",
  LEVEL_COUNT_SUCCESS: "LEVEL_COUNT_SUCCESS",
  LEVEL_COUNT_FAILURE: "LEVEL_COUNT_FAILURE",
};

export const userAction = {
  CREATE_USER_INITIATE: "CREATE_USER_INITIATE",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
  USER_LIST_INITIATE: "USER_LIST_INITIATE",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAILURE: "USER_LIST_FAILURE",
  USER_UPDATE_STATUS_INITIATE: "USER_UPDATE_STATUS_INITIATE",
  USER_UPDATE_STATUS_SUCCESS: "USER_UPDATE_STATUS_SUCCESS",
  USER_UPDATE_STATUS_FAILURE: "USER_UPDATE_STATUS_FAILURE",
  USER_DETAILS_INITIATE: "USER_DETAILS_INITIATE",
  USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
  USER_DETAILS_FAILURE: "USER_DETAILS_FAILURE",
  EDIT_USER_INITIATE: "EDIT_USER_INITIATE",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE",
  SESSION_DETAILS_INITIATE: "SESSION_DETAILS_INITIATE",
  SESSION_DETAILS_SUCCESS: "SESSION_DETAILS_SUCCESS",
  SESSION_DETAILS_FAILURE: "SESSION_DETAILS_FAILURE",
};

export const categoryAction = {
  CREATE_CATEGORY_INITIATE: "CREATE_CATEGORY_INITIATE",
  CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
  CREATE_CATEGORY_FAILURE: "CREATE_CATEGORY_FAILURE",
  CATEGORY_LIST_INITIATE: "CATEGORY_LIST_INITIATE",
  CATEGORY_LIST_SUCCESS: "CATEGORY_LIST_SUCCESS",
  CATEGORY_LIST_FAILURE: "CATEGORY_LIST_FAILURE",
  CATEGORY_DETAIL_INITIATE: "CATEGORY_DETAIL_INITIATE",
  CATEGORY_DETAIL_SUCCESS: "CATEGORY_DETAIL_SUCCESS",
  CATEGORY_DETAIL_FAILURE: "CATEGORY_DETAIL_FAILURE",
  CATEGORY_UPDATE_STATUS_INITIATE: "CATEGORY_UPDATE_STATUS_INITIATE",
  CATEGORY_UPDATE_STATUS_SUCCESS: "CATEGORY_UPDATE_STATUS_SUCCESS",
  CATEGORY_UPDATE_STATUS_FAILURE: "CATEGORY_UPDATE_STATUS_FAILURE",
  CATEGORY_DETAILS_INITIATE: "CATEGORY_DETAILS_INITIATE",
  CATEGORY_DETAILS_SUCCESS: "CATEGORY_DETAILS_SUCCESS",
  CATEGORY_DETAILS_FAILURE: "CATEGORY_DETAILS_FAILURE",
  EDIT_CATEGORY_INITIATE: "EDIT_CATEGORY_INITIATE",
  EDIT_CATEGORY_SUCCESS: "EDIT_CATEGORY_SUCCESS",
  EDIT_CATEGORY_FAILURE: "EDIT_CATEGORY_FAILURE",
  CATEGORY_DROPDOWN_LIST_INITIATE: "CATEGORY_DROPDOWN_LIST_INITIATE",
  CATEGORY_DROPDOWN_LIST_SUCCESS: "CATEGORY_DROPDOWN_LIST_SUCCESS",
  CATEGORY_DROPDOWN_LIST_FAILURE: "CATEGORY_DROPDOWN_LIST_FAILURE",
  DELETE_CATEGORY_INITIATE: "DELETE_CATEGORY_INITIATE",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",
};

export const questionAction = {
  UPLOAD_QUESTION_INITIATE: "UPLOAD_QUESTION_INITIATE",
  UPLOAD_QUESTION_SUCCESS: "UPLOAD_QUESTION_SUCCESS",
  UPLOAD_QUESTION_FAILURE: "UPLOAD_QUESTION_FAILURE",
  QUESTION_LIST_INITIATE: "QUESTION_LIST_INITIATE",
  QUESTION_LIST_SUCCESS: "QUESTION_LIST_SUCCESS",
  QUESTION_LIST_FAILURE: "QUESTION_LIST_FAILURE",
  CREATE_QUESTION_INITIATE: "CREATE_QUESTION_INITIATE",
  CREATE_QUESTION_SUCCESS: "CREATE_QUESTION_SUCCESS",
  CREATE_QUESTION_FAILURE: "CREATE_QUESTION_FAILURE",
  QUESTION_DETAILS_INITIATE: "QUESTION_DETAILS_INITIATE",
  QUESTION_DETAILS_SUCCESS: "QUESTION_DETAILS_SUCCESS",
  QUESTION_DETAILS_FAILURE: "QUESTION_DETAILS_FAILURE",
  UPDATE_QUESTION_INITIATE: "UPDATE_QUESTION_INITIATE",
  UPDATE_QUESTION_SUCCESS: "UPDATE_QUESTION_SUCCESS",
  UPDATE_QUESTION_FAILURE: "UPDATE_QUESTION_FAILURE",
  DELETE_QUESTION_INITIATE: "DELETE_QUESTION_INITIATE",
  DELETE_QUESTION_SUCCESS: "DELETE_QUESTION_SUCCESS",
  DELETE_QUESTION_FAILURE: "DELETE_QUESTION_FAILURE",
  FLAG_REVIEW_QUESTION_INITIATE: "FLAG_REVIEW_QUESTION_INITIATE",
  FLAG_REVIEW_QUESTION_SUCCESS: "FLAG_REVIEW_QUESTION_SUCCESS",
  FLAG_REVIEW_QUESTION_FAILURE: "FLAG_REVIEW_QUESTION_FAILURE",
  QUESTION_UPDATE_STATUS_INITIATE: "QUESTION_UPDATE_STATUS_INITIATE",
  QUESTION_UPDATE_STATUS_SUCCESS: "QUESTION_UPDATE_STATUS_SUCCESS",
  QUESTION_UPDATE_STATUS_FAILURE: "QUESTION_UPDATE_STATUS_FAILURE",
  DELETE_MANY_QUESTION_INITIATE: "DELETE_MANY_QUESTION_INITIATE",
  DELETE_MANY_QUESTION_SUCCESS: "DELETE_MANY_QUESTION_SUCCESS",
  DELETE_MANY_QUESTION_FAILURE: "DELETE_MANY_QUESTION_FAILURE",
  CATEGORY_QUESTION_INITIATE: "CATEGORY_QUESTION_INITIATE",
  CATEGORY_QUESTION_SUCCESS: "CATEGORY_QUESTION_SUCCESS",
  CATEGORY_QUESTION_FAILURE: "CATEGORY_QUESTION_FAILURE",
  QUESTION_PAGE_NUMBER: "QUESTION_PAGE_NUMBER",
  QUESTION_CATEGORY: "QUESTION_CATEGORY",
  QUESTION_CODE: "QUESTION_CODE",
  EXPORTQUESTION_LIST_INITIATE: "EXPORTQUESTION_LIST_INITIATE",
  EXPORTQUESTION_LIST_SUCCESS: "EXPORTQUESTION_LIST_SUCCESS",
  EXPORTQUESTION_LIST_FAILURE: "EXPORTQUESTION_LIST_FAILURE",
  CLEAR_QUESTION_STATE:"CLEAR_QUESTION_STATE"
};

export const superCategoryAction = {
  CREATE_SUPER_CATEGORY_INITIATE: "CREATE_SUPER_CATEGORY_INITIATE",
  CREATE_SUPER_CATEGORY_SUCCESS: "CREATE_SUPER_CATEGORY_SUCCESS",
  CREATE_SUPER_CATEGORY_FAILURE: "CREATE_SUPER_CATEGORY_FAILURE",
  SUPER_CATEGORY_LIST_INITIATE: "SUPER_CATEGORY_LIST_INITIATE",
  SUPER_CATEGORY_LIST_SUCCESS: "SUPER_CATEGORY_LIST_SUCCESS",
  SUPER_CATEGORY_LIST_FAILURE: "SUPER_CATEGORY_LIST_FAILURE",
  SUPER_CATEGORY_DETAIL_INITIATE: "SUPER_CATEGORY_DETAIL_INITIATE",
  SUPER_CATEGORY_DETAIL_SUCCESS: "SUPER_CATEGORY_DETAIL_SUCCESS",
  SUPER_CATEGORY_DETAIL_FAILURE: "SUPER_CATEGORY_DETAIL_FAILURE",
  EDIT_SUPER_CATEGORY_INITIATE: "EDIT_SUPER_CATEGORY_INITIATE",
  EDIT_SUPER_CATEGORY_SUCCESS: "EDIT_SUPER_CATEGORY_SUCCESS",
  EDIT_SUPER_CATEGORY_FAILURE: "EDIT_SUPER_CATEGORY_FAILURE",
  DELETE_SUPER_CATEGORY_INITIATE: "DELETE_SUPER_CATEGORY_INITIATE",
  DELETE_SUPER_CATEGORY_SUCCESS: "DELETE_SUPER_CATEGORY_SUCCESS",
  DELETE_SUPER_CATEGORY_FAILURE: "DELETE_SUPER_CATEGORY_FAILURE",

  SUPER_CATEGORY_DROPDOWN_LIST_INITIATE:
    "SUPER_CATEGORY_DROPDOWN_LIST_INITIATE",
  SUPER_CATEGORY_DROPDOWN_LIST_SUCCESS: "SUPER_CATEGORY_DROPDOWN_LIST_SUCCESS",
  SUPER_CATEGORY_DROPDOWN_LIST_FAILURE: "SUPER_CATEGORY_DROPDOWN_LIST_FAILURE",
};

export const usdPriceAction = {
  USD_PRICE_INITIATE: "USD_PRICE_INITIATE",
  USD_PRICE_SUCCESS: "USD_PRICE_SUCCESS",
  USD_PRICE_FAILURE: "USD_PRICE_FAILURE",

  USD_PRICE_LIST_INITIATE: "USD_PRICE_LIST_INITIATE",
  USD_PRICE_LIST_SUCCESS: "USD_PRICE_LIST_SUCCESS",
  USD_PRICE_LIST_FAILURE: "USD_PRICE_LIST_FAILURE",
};

export const transactionTokenAction = {
  TRANSACTION_TOKEN_ADD_INITIATE: "TRANSACTION_TOKEN_ADD_INITIATE",
  TRANSACTION_TOKEN_ADD_SUCCESS: "TRANSACTION_TOKEN_ADD_SUCCESS",
  TRANSACTION_TOKEN_ADD_FAILURE: "TRANSACTION_TOKEN_ADD_FAILURE",
  TRANSACTION_TOKEN_LIST_INITIATE: "TRANSACTION_TOKEN_LIST_INITIATE",
  TRANSACTION_TOKEN_LIST_SUCCESS: "TRANSACTION_TOKEN_LIST_SUCCESS",
  TRANSACTION_TOKEN_LIST_FAILURE: "TRANSACTION_TOKEN_LIST_FAILURE",
};

export const collectionAction = {
  CREATE_COLLECTION_INITIATE: "CREATE_COLLECTION_INITIATE",
  CREATE_COLLECTION_SUCCESS: "CREATE_COLLECTION_SUCCESS",
  CREATE_COLLECTION_FAILURE: "CREATE_COLLECTION_FAILURE",
  COLLECTION_LIST_INITIATE: "COLLECTION_LIST_INITIATE",
  COLLECTION_LIST_SUCCESS: "COLLECTION_LIST_SUCCESS",
  COLLECTION_LIST_FAILURE: "COLLECTION_LIST_FAILURE",
  COLLECTION_DETAIL_INITIATE: "COLLECTION_DETAIL_INITIATE",
  COLLECTION_DETAIL_SUCCESS: "COLLECTION_DETAIL_SUCCESS",
  COLLECTION_DETAIL_FAILURE: "COLLECTION_DETAIL_FAILURE",
};

export const nftAction = {
  CREATE_NFT_INITIATE: "CREATE_NFT_INITIATE",
  CREATE_NFT_SUCCESS: "CREATE_NFT_SUCCESS",
  CREATE_NFT_FAILURE: "CREATE_NFT_FAILURE",
  NFT_LIST_INITIATE: "NFT_LIST_INITIATE",
  NFT_LIST_SUCCESS: "NFT_LIST_SUCCESS",
  NFT_LIST_FAILURE: "NFT_LIST_FAILURE",
  NFT_DETAIL_INITIATE: "NFT_DETAIL_INITIATE",
  NFT_DETAIL_SUCCESS: "NFT_DETAIL_SUCCESS",
  NFT_DETAIL_FAILURE: "NFT_DETAIL_FAILURE",
  COLLECTION_NFT_LIST_INITIATE: "COLLECTION_NFT_LIST_INITIATE",
  COLLECTION_NFT_LIST_SUCCESS: "COLLECTION_NFT_LIST_SUCCESS",
  COLLECTION_NFT_LIST_FAILURE: "COLLECTION_NFT_LIST_FAILURE",
};

export const unitAction = {
  CREATE_UNIT_INITIATE: "CREATE_UNIT_INITIATE",
  CREATE_UNIT_SUCCESS: "CREATE_UNIT_SUCCESS",
  CREATE_UNIT_FAILURE: "CREATE_UNIT_FAILURE",
  UNIT_LIST_INITIATE: "UNIT_LIST_INITIATE",
  UNIT_LIST_SUCCESS: "UNIT_LIST_SUCCESS",
  UNIT_LIST_FAILURE: "UNIT_LIST_FAILURE",
  UNIT_UPDATE_INITIATE: "UNIT_UPDATE_INITIATE",
  UNIT_UPDATE_SUCCESS: "UNIT_UPDATE_SUCCESS",
  UNIT_UPDATE_FAILURE: "UNIT_UPDATE_FAILURE",
  DELETE_UNIT_INITIATE: "DELETE_UNIT_INITIATE",
  DELETE_UNIT_SUCCESS: "DELETE_UNIT_SUCCESS",
  DELETE_UNIT_FAILURE: "DELETE_UNIT_FAILURE",
  UNIT_DETAILS_INITIATE: "UNIT_DETAILS_INITIATE",
  UNIT_DETAILS_SUCCESS: "UNIT_DETAILS_SUCCESS",
  UNIT_DETAILS_FAILURE: "UNIT_DETAILS_FAILURE",
  UNIT_DROPDOWN_LIST_INITIATE: "UNIT_DROPDOWN_LIST_INITIATE",
  UNIT_DROPDOWN_LIST_SUCCESS: "UNIT_DROPDOWN_LIST_SUCCESS",
  UNIT_DROPDOWN_LIST_FAILURE: "UNIT_DROPDOWN_LIST_FAILURE",
  CLEAR_UNIT_STATE: "CLEAR_UNIT_STATE"
};

export const battalionAction = {
  CREATE_BATTALION_INITIATE: "CREATE_BATTALION_INITIATE",
  CREATE_BATTALION_SUCCESS: "CREATE_BATTALION_SUCCESS",
  CREATE_BATTALION_FAILURE: "CREATE_BATTALION_FAILURE",
  BATTALION_LIST_INITIATE: "BATTALION_LIST_INITIATE",
  BATTALION_LIST_SUCCESS: "BATTALION_LIST_SUCCESS",
  BATTALION_LIST_FAILURE: "BATTALION_LIST_FAILURE",
  BATTALION_UPDATE_INITIATE: "BATTALION_UPDATE_INITIATE",
  BATTALION_UPDATE_SUCCESS: "BATTALION_UPDATE_SUCCESS",
  BATTALION_UPDATE_FAILURE: "BATTALION_UPDATE_FAILURE",
  DELETE_BATTALION_INITIATE: "DELETE_BATTALION_INITIATE",
  DELETE_BATTALION_SUCCESS: "DELETE_BATTALION_SUCCESS",
  DELETE_BATTALION_FAILURE: "DELETE_BATTALION_FAILURE",
  BATTALION_DETAILS_INITIATE: "BATTALION_DETAILS_INITIATE",
  BATTALION_DETAILS_SUCCESS: "BATTALION_DETAILS_SUCCESS",
  BATTALION_DETAILS_FAILURE: "BATTALION_DETAILS_FAILURE",
  BATTALION_DROPDOWN_LIST_INITIATE: "BATTALION_DROPDOWN_LIST_INITIATE",
  BATTALION_DROPDOWN_LIST_SUCCESS: "BATTALION_DROPDOWN_LIST_SUCCESS",
  BATTALION_DROPDOWN_LIST_FAILURE: "BATTALION_DROPDOWN_LIST_FAILURE",
  CLEAR_BATTALION_STATE: "CLEAR_BATTALION_STATE"
};