import React, { useState, useEffect } from "react";
import HeaderAfterLogin from "../../common-components/header-afterlogin/header-afterlogin";
import SideBar from '../../common-components/sidebar/sidebar';
import DetailsRightsidebar from './info-form-details-rightsidebar';
import "./info-form-details.scss";


const InfoForm = () => {

  return (
    <>
      <div className="mainBg">
      <DetailsRightsidebar />   
      </div>
    </>
  );
};

export default InfoForm;
