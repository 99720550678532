

import { battalionAction, unitAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'
import { multiPartData } from '../../utils'

//-----------------------------------Create Battalion ---------------------------------------//

export const createBattalionInitiate = () => ({
  type: battalionAction.CREATE_BATTALION_INITIATE,
})

export const createBattalionSuccess = (data) => ({
  type: battalionAction.CREATE_BATTALION_SUCCESS,
  payload: data,
})

export const createBattalionFailure = (data) => ({
  type: battalionAction.CREATE_BATTALION_FAILURE,
})

export function createBattalion(payload) {
  return (dispatch) => {
    let data = multiPartData(payload)
    dispatch(createBattalionInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}createBattalion`, data, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(createBattalionSuccess(data))
          } else {
            dispatch(createBattalionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(createBattalionFailure(err))
          reject(err)
        }),
    )
  }
}


//----------------------------------- Battalion list ---------------------------------------//

export const battalionListInitiate = () => ({
  type: battalionAction.BATTALION_LIST_INITIATE,
})

export const battalionListSuccess = (data) => ({
  type: battalionAction.BATTALION_LIST_SUCCESS,
  payload: data,
})

export const battalionListFailure = () => ({
  type: battalionAction.BATTALION_LIST_FAILURE,
})

export function battalionList(payload) {
  return (dispatch) => {
    dispatch(battalionListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}battalionList?pageNumber=${payload.pageNumber}&search=${payload.searchVal}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(battalionListSuccess(data))
          } else {
            dispatch(battalionListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(battalionListFailure(err))
          reject(err)
        }),
    )
  }
}

// ---------------------------------------------Battalion Details--------------------------------------

export const battalionDetailsInitiate = () => ({
  type: battalionAction.BATTALION_DETAILS_INITIATE,
})

export const battalionDetailsSuccess = (data) => ({
  type: battalionAction.BATTALION_DETAILS_SUCCESS,
  payload: data,
})

export const battalionDetailsFailure = (data) => ({
  type: battalionAction.BATTALION_DETAILS_FAILURE,
})

export function battalionDetails(payload) {
  return (dispatch) => {
    dispatch(battalionDetailsInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}battalionDetails?battalionId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(battalionDetailsSuccess(data))
          } else {
            dispatch(battalionDetailsFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(battalionDetailsFailure(err))
          reject(err)
        }),
    )
  }
}



// ------------------------------------------UPDATE UNIT--------------------------------------]

export const updateBattalionInitiate = () => ({
  type: battalionAction.BATTALION_UPDATE_INITIATE,
})

export const updateBattalionSuccess = (data) => ({
  type: battalionAction.BATTALION_UPDATE_SUCCESS,
  payload: data,
})

export const updateBattalionFailure = (data) => ({
  type: battalionAction.BATTALION_UPDATE_FAILURE,
})

export function editBattalion(payload) {
  return (dispatch) => {
    let data = multiPartData(payload)
    dispatch(updateBattalionInitiate())
    return new Promise((resolve, reject) =>
      axios
        .put(`${config.Url}updateBattalion`, data, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(updateBattalionSuccess(data))
          } else {
            dispatch(updateBattalionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(updateBattalionFailure(err))
          reject(err)
        }),
    )
  }
}

// -------------------------------------DELETE UNIT------------------------------------

export const deleteBattalionInitiate = () => ({
  type: battalionAction.DELETE_BATTALION_INITIATE,
})

export const deleteBattalionSuccess = (data) => ({
  type: battalionAction.DELETE_BATTALION_SUCCESS,
  payload: data,
})

export const deleteBattalionFailure = (data) => ({
  type: battalionAction.DELETE_BATTALION_FAILURE,
})

export function deleteBattalion(payload) {
  return (dispatch) => {
    dispatch(deleteBattalionInitiate())
    return new Promise((resolve, reject) =>

      axios
        .delete(`${config.Url}deleteBattalion?battalionId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(deleteBattalionSuccess(data))
          } else {
            dispatch(deleteBattalionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(deleteBattalionFailure(err))
          reject(err)
        }),
    )
  }
}

//----------------------------------- Unit Dropdown List ---------------------------------------//

export const battalionDropdownListInitiate = () => ({
  type: battalionAction.BATTALION_DROPDOWN_LIST_INITIATE,
})

export const battalionDropdownListSuccess = (data) => ({
  type: battalionAction.BATTALION_DROPDOWN_LIST_SUCCESS,
  payload: data,
})

export const battalionDropdownListFailure = () => ({
  type: battalionAction.BATTALION_DROPDOWN_LIST_FAILURE,
})

export function battalionDropdownList(payload) {
  return (dispatch) => {
    dispatch(battalionDropdownListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}battalionDropDownList?unitId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(battalionDropdownListSuccess(data))
          } else {
            dispatch(battalionDropdownListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(battalionDropdownListFailure(err))
          reject(err)
        }),
    )
  }
}

// ----------------------------------------clear state-------------------------------------------

export const clearBattalionStateAction = () => ({
  type: battalionAction.CLEAR_BATTALION_STATE,
})

export function clearBattalionState() {
  return (dispatch) => {
   dispatch(clearBattalionStateAction())
  }
}