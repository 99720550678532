import React from 'react'
import "./App.scss";
import Layout from './layout/layout';

const App = () => {

  return (
    <div>

      <Layout />

    </div>

  )
}

export default App
