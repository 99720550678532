import React from "react";
import { Button } from 'reactstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import "./sidebar.scss";
import { MdOutlineDashboard } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { RiLogoutBoxLine } from "react-icons/ri";
import { IoHelpCircleOutline, IoFolder, IoSettings } from "react-icons/io5"

const Sidebar = () => {
  const navigate = useNavigate()
  const logOut = () => {
    localStorage.removeItem('adminToken');
    navigate("/")
  }
  return (
    <>
      <div className="siderbarBg">
        <div className="sideNav">
          <ul>
            <li>
              <NavLink activeClassName='active' to="/dashboard"><MdOutlineDashboard /> Dashboard</NavLink>
            </li>
            <li>
              <NavLink to="/moderators"><AiOutlineUser /> Moderator Management </NavLink>
            </li>
            <li>
              <NavLink to="/question"><IoHelpCircleOutline /> Question Management</NavLink>
            </li>
            <li>
              <NavLink to="/flagged-review"><IoHelpCircleOutline />Flagged For Review</NavLink>
            </li>
            <li>
              <NavLink to="/category"><IoFolder /> Category Management</NavLink>
            </li>
            <li>
              <NavLink to="/super-categroy"><IoFolder />Super Category </NavLink>
            </li>
            <li>
              <NavLink to="/battalion"><IoFolder /> Battalion Management</NavLink>
            </li>
            <li>
              <NavLink to="/unit"><IoFolder /> Unit Management</NavLink>
            </li>
            <li>
              <NavLink to="/setting"><IoSettings /> Setting</NavLink>
            </li>
            <li>
            <a href="javascript:void(0)" onClick={logOut}><RiLogoutBoxLine /> Logout</a>
              </li>
          </ul>
        </div>

      </div>
    </>
  );
};

export default Sidebar;
