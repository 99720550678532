import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Label, Input } from 'reactstrap';
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, Link } from 'react-router-dom';
import _ from 'lodash'
import { editBattalion, battalionDetails, clearBattalionState } from "../../../redux/action/battalionAction";
import ImageIcon from "../../../assets/images/img-icon.png";
import { unitDropdownList } from "../../../redux/action/unitAction";

const initialState = {
    battalion: "",
    unit: "",
    errors: "",
    battalionImage: "",
    battalionImageReview: "",
};

const Rightsidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [iState, updateState] = useState(initialState);
    const detailData = useSelector((state) => state.battalion);
    const { unitDropdownListData } = useSelector((state) => state.unit)
    const { battalionData, loader } = detailData;
    let battalionId = localStorage.getItem('battalionId')
    const {
        battalion,
        unit,
        battalionImage,
        battalionImageReview,
        errors } = iState;

    useEffect(() => {
        dispatch(battalionDetails(battalionId));
    }, [battalionDetails]);

    useEffect(() => {
        dispatch(unitDropdownList())
    }, [unitDropdownList])


    useEffect(() => {
        if (!_.isEmpty(battalionData)) {
            const updateData = _.cloneDeep(iState)
            updateData.battalion = battalionData.data && battalionData.data.battalion || ''
            updateData.unit = battalionData.data && battalionData.data.unit || ''
            updateData.battalionImage = battalionData.data && battalionData.data.battalionImage || ''
            updateState(
                updateData
            )
        }

    }, [battalionData])


    const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value,
        });
    };

    const fileHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png"
            ) {
                previewsFile(file);
            } else {
                toast.error("Only formats are allowed: jpeg, jpg, png files", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const previewsFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener(
            "load",
            () => {
                updateState({
                    ...iState,
                    battalionImageReview: reader.result,
                    battalionImage: file,
                });
            },
            false
        );
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    let handleValidation = () => {

        let unitEmpty = ''
        let battalionEmpty = ''
        let formIsValid = true;
        if (!battalion) {
            battalionEmpty = "Please insert battalion";
            formIsValid = false;
        }
        if (!unit) {
            unitEmpty = "Please select unit";
            formIsValid = false;
        }
        updateState({
            ...iState, errors: { unitEmpty, battalionEmpty }

        })
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let formIsValid = handleValidation()
        if (formIsValid) {
            const data = {
                battalionId,
                battalion,
                unit,
                battalionImage
            }
            dispatch(editBattalion(data))
                .then((res) => {
                    if (res.code == 200) {

                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        setTimeout(() => {
                            navigate('/battalion')
                        }, 2000)
                    } else {
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        updateState({
                            ...iState,
                            errorMsg: res.message,
                            battalionEmpty: '',
                            unitEmpty: '',
                        })
                    }
                })
                .catch((err) => {
                    console.log(`error>>>> ${err}`)
                })
        }
    }

    const clearState = () => {
        dispatch(clearBattalionState())
    }

    return (
        <>
            <div className="dashRightside">
                <div className="nftsOuterBx">
                    <ToastContainer />
                    <div className="nftDetHd"><Link to="/battalion" onClick={clearState} ><BiArrowBack /></Link> Edit Battalion</div>

                    <div className="createfrmOuter">
                        <div className="createFrmBx">
                            <Label>Unit*</Label>
                            <select
                                name="unit"
                                value={unit}
                                onChange={handleChange}
                                className="createInp wd-100"
                                placeholder="Select Unit"
                            //  style={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', color: 'white', height:"50px"}}
                            >
                                <option value="">Select Unit</option>
                                {unitDropdownListData && unitDropdownListData.data && unitDropdownListData.data.map((option, index) => {
                                    return (
                                        <option key={index} value={option._id}>{option.unit}</option>
                                    )
                                })}
                            </select>
                            <span style={{ color: "red" }}>{errors.unitEmpty}</span>
                        </div>
                        <div className="uploadNftImage">
                            <Label>Image</Label>

                            <i style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {loader ? (<h5 style={{ color: "white" }}>Loading</h5>) : battalionImageReview || battalionImage ? (
                                    <img src={battalionImageReview || battalionImage} alt="" />
                                ) : (
                                    <img src={ImageIcon} alt="" />
                                )}

                                <Input
                                    type="file"
                                    className="createnftFile"
                                    onChange={fileHandler}
                                    onClick={(e) => e.target.value == null}
                                    accept=".png, .jpg, .jpeg"
                                />
                            </i>
                        </div>
                        <div className="createFrmBx">
                            <Label>Battalion Name*</Label>
                            <Input type="text"
                                name="battalion"
                                value={battalion}
                                onChange={handleChange} className="createInp" />
                            <span style={{ color: "red" }}>{errors.battalionEmpty}</span>
                        </div>

                        <div className="createBtn">
                            <Button onClick={handleSubmit}>Save</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rightsidebar;
