
import {superCategoryAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'

//-----------------------------------Create User ---------------------------------------//

export const createSuperCategoryInitiate = () => ({
  type: superCategoryAction.CREATE_SUPER_CATEGORY_INITIATE,
})

export const createSuperCategorySuccess = (data) => ({
  type: superCategoryAction.CREATE_SUPER_CATEGORY_SUCCESS,
  payload: data,
})

export const createSuperCategoryFailure = (data) => ({
  type: superCategoryAction.CREATE_SUPER_CATEGORY_FAILURE,
})

export function createSuperCategory(payload) {
  return (dispatch) => {
    dispatch(createSuperCategoryInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}createSuperCategory`, payload, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(createSuperCategorySuccess(data))
          } else {
            dispatch(createSuperCategoryFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(createSuperCategoryFailure(err))
          reject(err)
        }),
    )
  }
}


//----------------------------------- user LIST ---------------------------------------//

export const superCategoryListInitiate = () => ({
  type: superCategoryAction.SUPER_CATEGORY_LIST_INITIATE,
})

export const superCategoryListSuccess = (data) => ({
  type: superCategoryAction.SUPER_CATEGORY_LIST_SUCCESS,
  payload: data,
})

export const superCategoryListFailure = () => ({
  type: superCategoryAction.SUPER_CATEGORY_LIST_FAILURE,
})

export function superCategoryList(payload) {
  return (dispatch) => {
    dispatch(superCategoryListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}superCategoryList?pageNumber=${payload.pageNumber}&search=${payload.searchVal}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(superCategoryListSuccess(data))
          } else {
            dispatch(superCategoryListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(superCategoryListFailure(err))
          reject(err)
        }),
    )
  }
}




export const superCategoryDetailsInitiate = () => ({
  type: superCategoryAction.SUPER_CATEGORY_DETAIL_INITIATE,
})

export const superCategoryDetailsSuccess = (data) => ({
  type: superCategoryAction.SUPER_CATEGORY_DETAIL_SUCCESS,
  payload: data,
})

export const superCategoryDetailsFailure = (data) => ({
  type: superCategoryAction.SUPER_CATEGORY_DETAIL_FAILURE,
})

export function superCategoryDetails(payload) {
  return (dispatch) => {
    dispatch(superCategoryDetailsInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}superCategoryDetails?supercatId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(superCategoryDetailsSuccess(data))
          } else {
            dispatch(superCategoryDetailsFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(superCategoryDetailsFailure(err))
          reject(err)
        }),
    )
  }
}


export const editSuperCategoryInitiate = () => ({
  type: superCategoryAction.EDIT_SUPER_CATEGORY_INITIATE,
})

export const editSuperCategorySuccess = (data) => ({
  type: superCategoryAction.EDIT_SUPER_CATEGORY_SUCCESS,
  payload: data,
})

export const editSuperCategoryFailure = (data) => ({
  type: superCategoryAction.EDIT_SUPER_CATEGORY_FAILURE,
})

export function editSuperCategory(payload) {
  return (dispatch) => {
    dispatch(editSuperCategoryInitiate())
    return new Promise((resolve, reject) =>
      axios
        .put(`${config.Url}updateSuperCategory`, payload, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(editSuperCategorySuccess(data))
          } else {
            dispatch(editSuperCategoryFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(editSuperCategoryFailure(err))
          reject(err)
        }),
    )
  }
}

export const deleteSuperCategoryInitiate = () => ({
  type: superCategoryAction.DELETE_SUPER_CATEGORY_INITIATE,
})

export const deleteSuperCategorySuccess = (data) => ({
  type: superCategoryAction.DELETE_SUPER_CATEGORY_SUCCESS,
  payload: data,
})

export const deleteSuperCategoryFailure = (data) => ({
  type: superCategoryAction.DELETE_SUPER_CATEGORY_FAILURE,
})

export function deleteSuperCategory(payload) {
  return (dispatch) => {
    dispatch(deleteSuperCategoryInitiate())
    return new Promise((resolve, reject) =>

      axios
        .delete(`${config.Url}deleteSuperCategory?supercatId=${payload}`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(deleteSuperCategorySuccess(data))
          } else {
            dispatch(deleteSuperCategoryFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(deleteSuperCategoryFailure(err))
          reject(err)
        }),
    )
  }
}


//----------------------------------- Cetegory Dropdown List ---------------------------------------//

export const superCategoryDropdownListInitiate = () => ({
  type: superCategoryAction.SUPER_CATEGORY_DROPDOWN_LIST_INITIATE,
})

export const superCategoryDropdownListSuccess = (data) => ({
  type: superCategoryAction.SUPER_CATEGORY_DROPDOWN_LIST_SUCCESS,
  payload: data,
})

export const superCategoryDropdownListFailure = () => ({
  type: superCategoryAction.SUPER_CATEGORY_DROPDOWN_LIST_FAILURE,
})

export function superCategoryDropdownList() {
  return (dispatch) => {
    dispatch(superCategoryDropdownListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}superCategoryDropdownList`, {
          headers: { token: localStorage.getItem('adminToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(superCategoryDropdownListSuccess(data))
          } else {
            dispatch(superCategoryDropdownListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(superCategoryDropdownListFailure(err))
          reject(err)
        }),
    )
  }
}